import React from "react";
import { Dialog, Button, Typography } from "@mui/material";
import closeImg from "../../assets/images/close.svg";

const ConfirmDialog = ({ open, onClose, onConfirm, text, boldText }) => {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      PaperProps={{
        className: "p-5 rounded-3xl",
        sx: {
          width: "30%",
        },
      }}
    >
      <div className="flex flex-col items-center">
        <div className="flex justify-between items-center w-full mb-3 px-3">
          <div />
          <img
            src={closeImg}
            alt="Close"
            className="cursor-pointer"
            style={{ justifySelf: "flex-end" }}
            onClick={onClose}
          />
        </div>
        <Typography variant="body2" className="text-center mb-5 text-sm">
          {text ? text : "Are you sure you want forward this job"}
          <Typography
            variant="body2"
            className="text-center mb-5 text-sm font-semibold"
          >
            {boldText}?
          </Typography>
        </Typography>

        <div className="flex justify-between w-2/3">
          <Button
            color="orange"
            variant="outlined"
            className="rounded-full font-bold w-40 border-2"
            onClick={onConfirm}
          >
            Yes
          </Button>
          <Button
            color="orange"
            variant="contained"
            className="rounded-full text-white font-bold w-40"
            onClick={onClose}
          >
            No
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmDialog;
