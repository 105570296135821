import { Typography, useTheme } from "@mui/material";
import React from "react";

export const HeadingwithData = ({ heading, data }) => {
  const theme = useTheme();
  return (
    <div className="mb-3 w-11/12">
      <Typography
        className="text-sm font-medium mb-1"
        style={{ color: theme.palette.orange.main }}
      >
        {heading}
      </Typography>
      <Typography
        className="text-sm font-normal text-justify"
        style={{ colro: theme.palette.blue.main }}
      >
        {data}
      </Typography>
    </div>
  );
};
