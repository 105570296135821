import React, { useEffect, useState } from "react";
import { StaffLayout } from "../layouts/StaffLayout";
import { Navbar } from "../../components/commonComponents/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { getDriverJobs } from "../../redux-slices/jobsSlice";
import arrowdown from "../../assets/images/arrowDownWhite.svg";
import { CircularProgress } from "@mui/material";
import { useTheme } from "@emotion/react";
import { DriverTable } from "../../components/driverFlow/DriverTable";

export const Driver = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { jobs } = useSelector((state) => state.jobs);

  const [limit, setLimit] = useState(10);
  const [tab, setTab] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState([]);

  const fetchData = (search) => {
    setLoading(true);
    dispatch(
      getDriverJobs({ tab: tabData[tab].val, search, sort, limit })
    ).then(() => setLoading(false));
  };

  useEffect(() => {
    const handle = setTimeout(() => {
      fetchData(searchValue);
    }, 1000);

    return () => {
      clearTimeout(handle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    setSort([]);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  useEffect(() => {
    fetchData(searchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, limit]);

  const updateSorting = (headerId) => {
    const newSort = [...sort];
    const column = headerTitles[headerId].val;
    const index = newSort.indexOf(column);

    if (index === -1) {
      newSort.push(column);
    } else {
      newSort.splice(index, 1);
    }

    setSort(newSort);
  };

  const tabData = [
    { id: 0, val: "serviceorders", title: "Service Orders" },
    { id: 1, val: "generalorders", title: "Pickup Orders" },
  ];

  const headerTitles = [
    { id: 0, title: "Pick-Up #", show: tab === 1 },
    {
      id: 1,
      title: "Service Order ID",
      img: arrowdown,
      val: "job_id",
      show: tab === 0,
    },
    {
      id: 2,
      title: "Assigned at",
      img: arrowdown,
      val: "created_at",
      show: true,
    },
    { id: 3, title: "Make & Model", show: tab === 0 },
    { id: 4, title: "Vin/Chassis", show: tab === 0 },
    { id: 5, title: "Description", show: tab === 1 },
    { id: 6, title: "Parts", show: true },
  ];

  return (
    (document.title = "Carfix Curacao | Driver"),
    (
      <StaffLayout setTab={setTab} defaultTab={0}>
        <Navbar
          tab={tab}
          setTab={setTab}
          tabData={tabData}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        {loading ? (
          <CircularProgress
            sx={{ color: theme.palette.orange.main }}
            className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
          />
        ) : (
          <DriverTable
            jobs={jobs}
            headerTitles={headerTitles}
            sort={sort}
            setSorting={updateSorting}
            fetchData={fetchData}
            setLimit={setLimit}
            tab={tab}
            showPickupNumber={tab === 1}
            showServiceOrder={tab === 0}
            showMakeAndModel={tab === 0}
            showDescription={tab === 1}
          />
        )}
      </StaffLayout>
    )
  );
};
