import React from "react";
import { ClientLayout } from "../layouts/ClientLayout";
import welcomeRectangleImg from "../../assets/images/welcomeRectangle.png";
import gearsImg from "../../assets/images/gears.svg";
import welcomeCarImg from "../../assets/images/welcomeCar.png";
import toolsImg from "../../assets/images/tools.svg";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Button } from "@mui/material";
import AddIcon from "../../assets/images/add.svg";
import { Link } from "react-router-dom";

export const Welcome = () => {
  const theme = useTheme();
  return (
    (document.title = "Carfix Curacao | Welcome"),
    (
      <ClientLayout>
        <div
          className="bg-calc relative"
          style={{
            backgroundImage: `url(${welcomeRectangleImg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="flex absolute w-full" style={{ marginTop: "15%" }}>
            <div className="w-1/2 flex justify-center items-center">
              <img src={gearsImg} alt="" className="w-2/3" />
            </div>
            <div className="w-1/2">
              <img src={welcomeCarImg} alt="" />
            </div>
          </div>
          <div
            className="flex absolute w-full sm:pl-16 pl-8 flex-col"
            style={{ marginTop: "45%" }}
          >
            <Typography
              variant="h1"
              className="text-white font-bold sm:text-4xl text-xl"
              style={{
                textShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              Welcome To
            </Typography>
            <Typography
              className="poppins-bold sm:text-5xl sm:mt-2 text-2xl"
              style={{
                color: theme.palette.text.darkBlue,
                textShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              CARFIX CURACAO
            </Typography>
            <div className="text-white mt-4 pr-10 sm:text-base text-xs">
              <Typography>
                Your destination for expert automotive care. We specialize in
                vehicle scanning, parts ordering, wheel alignment, and
                comprehensive repairs for brakes, steering, suspension, and
                exhaust systems. Drive with confidence – we've got your journey
                covered.
              </Typography>
            </div>
            <div>
              <Link to="/order">
                <Button
                  className="mt-12 font-semibold h-16 p-6 sm:text-base"
                  variant="contained"
                  color="white"
                  style={{
                    borderRadius: 32,
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  }}
                  endIcon={
                    <img style={{ marginLeft: 8 }} src={AddIcon} alt="" />
                  }
                >
                  Order New Service
                </Button>
              </Link>
            </div>
          </div>
          <div className="absolute bottom-0 right-0">
            <img src={toolsImg} alt="" />
          </div>
        </div>
      </ClientLayout>
    )
  );
};
