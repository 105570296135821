import React, { useEffect, useState } from "react";
import { StaffLayout } from "../layouts/StaffLayout";
import {
  Button,
  capitalize,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import backImg from "../../assets/images/backArrow.svg";
import optionsBtn from "../../assets/images/optionsBtn.svg";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../components/commonComponents/StaffTable";
import { useDispatch } from "react-redux";
import { deleteUser, getGarageStaff } from "../../redux-slices/usersSlice";
import dayjs from "dayjs";
import UserDialog from "../../components/admin/UserDialog";
import { Link } from "react-router-dom";
import CustomPopover from "../../components/commonComponents/CustomerPopover";

const Settings = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [count, setCount] = useState(0);
  const [users, setUsers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopover, setOpenPopover] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [heading, setHeading] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);

  const getStaff = async () => {
    dispatch(getGarageStaff()).then((res) => {
      setCount(res.payload.count);
      setUsers(res.payload.users);
    });
  };

  useEffect(() => {
    getStaff();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleOptionsOpen = (event, user) => {
    setAnchorEl(event.currentTarget);
    setOpenPopover(true);
    setSelectedUser(user);
  };

  const handleAddUser = () => {
    setSelectedUser(null);
    setHeading("Add New User");
    setOpenModal(true);
  };

  const handleAction = (option) => {
    if (option.title === "Edit") {
      setHeading("Edit User");
      setOpenModal(true);
    }
    if (option.title === "Delete") {
      dispatch(
        deleteUser({
          id: selectedUser.id,
          onSuccess: () => {
            getStaff();
          },
        })
      );
      setSelectedUser(null);
    }
    setOpenPopover(false);
  };

  const headers = [
    { id: "role", label: "Role" },
    { id: "name", label: "Name" },
    { id: "email", label: "Email" },
    { id: "createdAt", label: "Date Created" },
    { id: "action", label: "Action" },
  ];
  const popoverOptions = [{ title: "Edit" }, { title: "Delete" }];

  return (
    (document.title = "Carfix Curacao | Admin"),
    (
      <StaffLayout>
        <div className="mt-7 md:px-24 sm:px-8 px-6">
          <div className="flex justify-between items-center w-48">
            <Link className="flex justify-center" to="/admin">
              <img className="cursor-pointer" src={backImg} alt="back" />
            </Link>
            <Typography
              className="font-bold text-3xl"
              style={{ color: theme.palette.blue.main }}
            >
              Settings
            </Typography>
          </div>

          {users?.length > 0 && (
            <div className="mt-4 flex justify-between items-end">
              <Typography
                className="font-bold text-2xl"
                style={{
                  color: theme.palette.blue.main,
                }}
              >
                ({count}) Users
              </Typography>
              <Button
                className="h-10 md:w-32 text-white rounded-lg text-base"
                style={{
                  textTransform: "none",
                  background: theme.palette.orange.main,
                }}
                onClick={handleAddUser}
              >
                Add User
              </Button>
            </div>
          )}

          {users?.length > 0 && (
            <div className="mt-4">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }}>
                  <TableHead>
                    <TableRow>
                      {headers.map((header) => (
                        <StyledTableCell key={header.id} align="center">
                          {header.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users?.map((user) => (
                      <StyledTableRow key={user.id}>
                        <StyledTableCell align="center">
                          {capitalize(user.roles[0].name)}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {user.name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {user.email}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {dayjs(user.created_at).format("YYYY-MM-DD HH:mm:ss")}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <div className="flex justify-center h-5">
                            <div
                              className="flex justify-center items-center w-1/3 cursor-pointer"
                              onClick={(event) =>
                                handleOptionsOpen(event, user)
                              }
                            >
                              <img src={optionsBtn} alt="actions" />
                            </div>
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
          <div>
            <CustomPopover
              openPopover={openPopover}
              setOpenPopover={setOpenPopover}
              anchorEl={anchorEl}
              popoverOptions={popoverOptions}
              handleAction={handleAction}
            />
          </div>
          <div>
            <UserDialog
              open={openModal}
              setOpen={setOpenModal}
              heading={heading}
              user={selectedUser}
              getStaff={getStaff}
            />
          </div>
        </div>
      </StaffLayout>
    )
  );
};

export default Settings;
