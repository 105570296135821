import React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

const CustomPopover = ({
  openPopover,
  setOpenPopover,
  anchorEl,
  popoverOptions,
  handleAction,
}) => {
  const theme = useTheme();

  return (
    <Popover
      open={openPopover}
      onClose={() => setOpenPopover(false)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      PaperProps={{
        sx: {
          borderRadius: 4,
          padding: 1,
          minWidth: 100,
        },
      }}
    >
      <div className="min-h-8 flex items-center justify-center flex-col">
        {popoverOptions.map((option) => (
          <Typography
            key={option.title}
            className="text-center cursor-pointer p-1"
            style={{
              color: theme.palette.blue.main,
            }}
            onClick={() => handleAction(option)}
          >
            {option.title}
          </Typography>
        ))}
      </div>
    </Popover>
  );
};

export default CustomPopover;
