import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  Button,
  Select,
  InputAdornment,
  IconButton,
} from "@mui/material";
import closeImg from "../../assets/images/close.svg";
import CarfixInput from "../commonComponents/CarfixInput";
import { useTheme } from "@emotion/react";
import { useDispatch } from "react-redux";
import { addUser, editUser } from "../../redux-slices/usersSlice";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const UserDialog = ({ open, setOpen, heading, user, getStaff }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [role, setRole] = useState("admin");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    if (user) {
      setRole(user.roles[0].name);
      setName(user.name);
      setEmail(user.email);
    }
    // eslint-disable-next-line
  }, [open]);

  const onClose = () => {
    setOpen(false);
    setRole("");
    setName("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
  };

  const handleSubmit = () => {
    if (heading === "Edit User") {
      if (
        role !== user.roles[0].name ||
        name !== user.name ||
        email !== user.email
      ) {
        const data = {
          id: user.id,
          role,
          name,
          email,
          password,
          confirmPassword,
        };

        updateUserData(data);
        onClose();
      } else if (password && confirmPassword && password === confirmPassword) {
        const data = {
          id: user.id,
          password,
        };
        updateUserData(data);
        onClose();
      } else {
        if (password !== confirmPassword) {
          alert("Passwords do not match");
        } else {
          onClose();
        }
      }
    } else {
      if (
        role &&
        name &&
        email &&
        password &&
        confirmPassword &&
        password === confirmPassword
      ) {
        const data = {
          role,
          name,
          email,
          password,
        };
        dispatch(addUser({ data, onSuccess: () => getStaff() }));
        onClose();
      } else {
        alert("Please fill all the fields or Passwords do not match");
      }
    }
  };

  const updateUserData = (data) => {
    dispatch(
      editUser({
        data,
        onSuccess: () => {
          onClose();
          getStaff();
        },
      })
    );
  };

  const roles = [
    { value: "admin", label: "Admin" },
    { value: "receptionist", label: "Receptionist" },
    { value: "supervisor", label: "Supervisor" },
    { value: "mechanic", label: "Mechanic" },
    { value: "driver", label: "Driver" },
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      PaperProps={{
        style: {
          borderRadius: 30,
          padding: 20,
        },
      }}
    >
      <DialogTitle>
        <div className="flex justify-between">
          <Typography
            className="text-3xl font-bold"
            style={{ color: theme.palette.blue.main }}
          >
            {heading}
          </Typography>
          <img
            src={closeImg}
            alt="Close Dialog"
            className="cursor-pointer"
            onClick={onClose}
          />
        </div>
      </DialogTitle>
      <DialogContent className="mt-2">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography
              className="mb-2 font-bold text-base"
              style={{ color: theme.palette.blue.main }}
            >
              Role:
            </Typography>
            <Select
              native
              className="w-full rounded-lg"
              value={role}
              onChange={(event) => setRole(event.target.value)}
            >
              {roles.map((role) => (
                <option key={role.value} value={role.value}>
                  {role.label}
                </option>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              className="mb-2 font-bold text-base"
              style={{ color: theme.palette.blue.main }}
            >
              Name:
            </Typography>
            <CarfixInput
              className="w-full"
              type="text"
              value={name}
              onChange={(event) => setName(event.target.value)}
              placeholder="Enter Name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              className="mb-2 font-bold text-base"
              style={{ color: theme.palette.blue.main }}
            >
              Email:
            </Typography>
            <CarfixInput
              className="w-full"
              value={email}
              type="email"
              onChange={(event) => setEmail(event.target.value)}
              placeholder="Enter Email"
            />
          </Grid>
        </Grid>
        <Grid className="mt-2" container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography
              className="mb-2 font-bold text-base"
              style={{ color: theme.palette.blue.main }}
            >
              Password:
            </Typography>
            <CarfixInput
              type={showPassword ? "text" : "password"}
              className="w-full"
              placeholder="********"
              onChange={(event) => setPassword(event.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(event) => event.preventDefault()}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              className="mb-2 font-bold text-base"
              style={{ color: theme.palette.blue.main }}
            >
              Confirm Password:
            </Typography>
            <CarfixInput
              type={showConfirmPassword ? "text" : "password"}
              className="w-full"
              placeholder="********"
              onChange={(event) => setConfirmPassword(event.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      onMouseDown={(event) => event.preventDefault()}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          className="h-10 md:w-36 text-white mt-7 md:mr-5 rounded-lg text-base"
          style={{
            textTransform: "none",
            background: theme.palette.orange.main,
          }}
          onClick={handleSubmit}
        >
          {heading === "Edit User" ? "Save" : "Add User"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserDialog;
