import React from "react";
import Typography from "@mui/material/Typography";
import deleteImg from "../../assets/images/imageDelete.svg";
import addWhiteImg from "../../assets/images/addWhite.svg";
import addBlueImg from "../../assets/images/addBlue.svg";
import uploadImg from "../../assets/images/upload.svg";
import { useTheme } from "@emotion/react";

const UploadedMediaGallery = ({
  media,
  setMedia,
  mediaRef,
  width,
  orange = false,
}) => {
  const theme = useTheme();

  const handleMediaSelect = (e) => {
    setMedia([...media, ...e.target.files]);
    e.target.value = null;
  };

  return (
    <div>
      <div className="flex flex-wrap items-center">
        {Boolean(media?.length) &&
          media.map((img, index) => (
            <div
              key={index}
              className={`rounded-lg relative mr-2 ${orange ? "mt-3" : "mt-1"}`}
              style={{
                borderWidth: 3,
                borderColor: theme.palette.orange.main,
              }}
            >
              <img
                src={URL.createObjectURL(img)}
                className="rounded w-28 h-28 object-cover"
                alt=""
              />
              <img
                src={deleteImg}
                alt="Delete"
                className="absolute cursor-pointer h-4 w-4"
                style={{ top: -8, right: -8 }}
                onClick={() => setMedia(media.filter((m) => m !== img))}
              />
            </div>
          ))}
        {media.length > 0 && (
          <div
            className={`flex flex-col justify-center items-center w-28 h-28 rounded-lg cursor-pointer ${
              orange ? "mt-3" : "mt-1"
            }`}
            style={{
              borderWidth: 2,
            }}
            onClick={() => mediaRef.current.click()}
          >
            {width ? (
              <img
                className="cursor-pointer"
                src={
                  width > theme.breakpoints.values.sm ? addWhiteImg : addBlueImg
                }
                alt="Add"
              />
            ) : (
              <img src={addWhiteImg} alt="Add" />
            )}

            <Typography className="text-xs mt-1 text-gray-400">
              Add More
            </Typography>
          </div>
        )}
      </div>
      {media.length < 1 && (
        <div>
          {orange ? (
            <Typography
              className="md:mt-0 text-sm font-medium mb-2"
              style={{ color: "#F3A366", fontSize: 14 }}
            >
              Upload Media
            </Typography>
          ) : (
            <label className="client-label" htmlFor="media">
              Upload Media
            </label>
          )}
          <img
            className="cursor-pointer mt-3"
            src={uploadImg}
            alt="Upload"
            onClick={() => mediaRef.current?.click()}
          />
        </div>
      )}
      <input
        multiple
        ref={mediaRef}
        id="picker"
        type="file"
        className="hidden"
        accept="image/*"
        onChange={handleMediaSelect}
      />
    </div>
  );
};

export default UploadedMediaGallery;
