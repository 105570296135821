import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { formatStatus } from "../../utils/helpers";

export const AdminModalTable = ({ headerTitles, data, partBy }) => {
  const theme = useTheme();
  const [parts, setParts] = useState([]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const formattedData = data.map((item) => ({
      part: item.name,
      quantity: item.quantity,
      status: item.status,
    }));
    setParts(formattedData);
  }, [data]);

  return (
    <div>
      <TableContainer
        className="rounded-lg mt-2 mb-5 shadow-md"
        component={Paper}
      >
        <Table>
          <TableHead style={{ backgroundColor: theme.palette.blue.main }}>
            <TableRow>
              {headerTitles.map((header) => (
                <TableCell
                  key={header.id}
                  className={`text-white text-center ${
                    header.id === 0 ? "text-start" : ""
                  }
                  ${
                    header.id === 2
                      ? partBy !== "customer"
                        ? ""
                        : "hidden"
                      : ""
                  }
                  `}
                >
                  {header.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {parts.map((part, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell className="text-start">
                  <Typography>{part.part}</Typography>
                </TableCell>
                <TableCell className="text-center">
                  <Typography>{part.quantity}</Typography>
                </TableCell>
                {partBy !== "customer" && (
                  <TableCell className="p-0">
                    <Typography className="text-center">
                      {formatStatus(part.status)}
                    </Typography>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
