import { addMedia, addRemarks } from "../redux-slices/jobsSlice";

export const truncateText = (text, maxWords) => {
  if (!text) return "";
  const words = text.split(" ");
  if (words.length > maxWords) {
    return words.slice(0, maxWords).join(" ") + "...";
  }
  return text;
};

export const getStatusColor = (status) => {
  switch (status) {
    case "completed":
      return "#2F80ED";
    case "in-progress":
      return "#27AE60";
    case "awaiting-parts":
      return "#E2B93B";
    case "awaiting-review":
      return "#E2B93B";
    case "to-be-assigned":
      return "#EB5757";
    case "assigned-to-supervisor":
      return "#E2B93B";
    case "reassigned":
      return "#27AE60";
    case "new-order":
      return "#2F80ED";
    case "awaiting-mechanic":
      return "#E2B93B";
    case "pending":
      return "#E2B93B";
    case "cancelled":
      return "#EB5757";
    default:
      return "gray";
  }
};

export const formatStatus = (status) => {
  if (!status) return "";
  return status
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const dropdownStatusOptions = [
  { label: "Pending", value: "pending" },
  { label: "Ready for Pickup", value: "ready-for-pickup" },
  { label: "Out for Pick up", value: "out-for-pick-up" },
  { label: "On Hand", value: "on-hand" },
  { label: "Delivered", value: "delivered" },
];

export const handleMediaSubmit = (
  media,
  id,
  setMedia,
  fetchJobData,
  setLoading,
  setUploadLoading,
  dispatch
) => {
  const formData = new FormData();
  media.forEach((file, index) => {
    formData.append(`media[${index}]`, file);
  });
  formData.append("job_id", id);

  setUploadLoading(true);

  dispatch(
    addMedia({
      data: formData,
      onSuccess: () => {
        setMedia([]);
        fetchJobData(setLoading);
        setUploadLoading(false);
      },
    })
  );
};

export const handleRemarksSubmit = (
  remarks,
  job_id,
  setRemarks,
  fetchJobData,
  setLoading,
  setUploadLoading,
  dispatch
) => {
  if (remarks === "") return;

  setUploadLoading(true);

  const data = {
    job_id: job_id,
    remarks: remarks,
  };

  dispatch(
    addRemarks({
      data: data,
      onSuccess: () => {
        setRemarks("");
        fetchJobData(setLoading);
        setUploadLoading(false);
      },
    })
  );
};
