import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CursorOrderImg from "../../assets/images/close.svg";
import { OrderData } from "../modalComponents/OrderData";
import { HeadingwithData } from "../modalComponents/HeadingwithData";
import { MediaWithHeading } from "../modalComponents/MediaWithHeading";
import { OrderRemarks } from "../modalComponents/OrderRemarks";
import { AddRemarks } from "../modalComponents/AddRemarks";
import ShowMedia from "../modalComponents/ShowMedia";
import { useDispatch, useSelector } from "react-redux";
import { completedByMechanic } from "../../redux-slices/jobsSlice";
import { handleMediaSubmit, handleRemarksSubmit } from "../../utils/helpers";
import { AdminModalTable } from "../admin/AdminModalTable";
import { useNavigate } from "react-router";
import UploadMedia from "../modalComponents/UploadMedia";
import PrintImg from "../../assets/images/PrintOrderImg.png";
import { getPdf } from "../../redux-slices/pdfSlice";

const MechanicModalDetails = ({
  jobModalOpen,
  closeModal,
  loading,
  setLoading,
  job,
  fetchJobData,
  id,
  fetchData,
}) => {
  const headerTitles = [
    {
      id: 0,
      title: "Name",
    },
    {
      id: 1,
      title: "Quantity",
    },
    {
      id: 2,
      title: "Status",
    },
  ];

  const [remarks, setRemarks] = useState("");
  const [media, setMedia] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);

  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (media.length > 0) {
      submitMedia();
    }
    // eslint-disable-next-line
  }, [media]);

  const submitMedia = () => {
    handleMediaSubmit(
      media,
      id,
      setMedia,
      fetchJobData,
      setLoading,
      setUploadLoading,
      dispatch
    );
  };

  const submitRemarks = () => {
    handleRemarksSubmit(
      remarks,
      id,
      setRemarks,
      fetchJobData,
      setLoading,
      setUploadLoading,
      dispatch
    );
  };

  const handleComplete = () => {
    if (job?.vehicle?.mileage === null || job?.vehicle?.mileage === "") {
      alert("Odometer reading is required.");
      return;
    }

    if (remarks) {
      submitRemarks();
    }

    dispatch(
      completedByMechanic({
        id,
        onSuccess: () => {
          fetchData();
          closeModal();
        },
      })
    );
  };

  const handleViewLogs = () => {
    if (remarks) {
      submitRemarks();
    }
    closeModal();
    navigate(`/${user?.roles?.[0]?.name}/logs/${id}`, {
      state: { job_id: id },
    });
  };

  const handlePrint = () => {
    dispatch(getPdf(id));
  };

  return (
    <Dialog
      open={jobModalOpen}
      onClose={closeModal}
      maxWidth="xl"
      scroll="paper"
      PaperProps={{
        style: {
          borderRadius: 30,
          padding: 10,
        },
      }}
    >
      <DialogTitle>
        <div className="flex justify-between">
          <div className="flex">
            <Typography
              className="text-3xl font-bold"
              style={{ color: theme.palette.blue.main }}
            >
              {loading ? "Service Order" : `Service Order ${job?.display_id}`}
            </Typography>
            <div className="flex justify-center items-center ml-5">
              <img
                src={PrintImg}
                alt="PrintImg"
                className="cursor-pointer"
                onClick={handlePrint}
              />
            </div>
          </div>
          <img
            src={CursorOrderImg}
            alt="CursorOrderImg"
            className="cursor-pointer"
            onClick={closeModal}
          />
        </div>
      </DialogTitle>

      <DialogContent className="mr-7 pl-7">
        {loading ? (
          <div className="h-60 w-96">
            <CircularProgress
              sx={{ color: theme.palette.orange.main }}
              className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
            />
          </div>
        ) : (
          <div className="orderModal justify-between">
            <div className="flex flex-col w-full 2xl:w-1/2">
              <OrderData editable data={job} fetchJobData={fetchJobData} />

              <HeadingwithData
                heading={"Service/Repair Required"}
                data={job?.description}
              />
              {job?.remarks.length > 0 && (
                <div>
                  <Typography
                    className="font-semibold text-xl mb-3 mt-4"
                    style={{ color: theme.palette.blue.main }}
                  >
                    Remarks
                  </Typography>
                  <OrderRemarks remarks={job?.remarks} />
                </div>
              )}

              <div className={job?.remarks?.length === 0 ? "mt-5" : ""}>
                <AddRemarks
                  remarks={remarks}
                  setRemarks={setRemarks}
                  submitRemarks={submitRemarks}
                />
              </div>
            </div>
            <div className="flex flex-col w-full 2xl:w-1/2">
              {job?.parts_by_customer.length > 0 && (
                <>
                  <Typography
                    className="text-sm"
                    style={{ color: theme.palette.orange.main }}
                  >
                    Parts Provided By User
                  </Typography>

                  <AdminModalTable
                    headerTitles={headerTitles}
                    data={job?.parts_by_customer}
                    partBy="customer"
                  />
                </>
              )}

              {job?.parts_by_garage.length > 0 && (
                <>
                  <Typography
                    className="text-sm"
                    style={{ color: theme.palette.orange.main }}
                  >
                    Pick up Requests
                  </Typography>
                  <AdminModalTable
                    headerTitles={headerTitles}
                    data={job?.parts_by_garage}
                    partBy="garage"
                  />
                </>
              )}
              {job?.media_by_customer.length > 0 && (
                <div className="mb-5 mt-5">
                  <MediaWithHeading
                    heading={"Client"}
                    source={job?.media_by_customer}
                  />
                </div>
              )}
              <div>
                <ShowMedia
                  userRole={user?.roles?.[0]?.name}
                  media={job?.media_by_garage}
                  fetchJobData={fetchJobData}
                />
              </div>
              <div>
                <UploadMedia media={media} setMedia={setMedia} />
              </div>
            </div>
          </div>
        )}
        {uploadLoading && (
          <div>
            <CircularProgress
              sx={{ color: theme.palette.orange.main }}
              className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          className="h-10 md:w-36 text-white mt-7 md:mr-5 rounded-lg text-base"
          style={{
            textTransform: "none",
            background: theme.palette.orange.main,
          }}
          onClick={handleViewLogs}
        >
          View Logs
        </Button>
        <Button
          className="h-10 md:w-36 text-white mt-7 md:mr-5 rounded-lg text-base"
          style={{
            textTransform: "none",
            background: theme.palette.orange.main,
          }}
          onClick={handleComplete}
        >
          Send for Review
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MechanicModalDetails;
