import React, { useState } from "react";
import logo from "../assets/images/logo.svg";
import nextImg from "../assets/images/rightArrow.svg";
import { useForm } from "react-hook-form";
import {
  Typography,
  Checkbox,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CarfixInput from "../components/commonComponents/CarfixInput";
import { useDispatch } from "react-redux";
import { loginUser } from "../redux-slices/authSlice";
import { useNavigate } from "react-router";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export const Login = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    dispatch(
      loginUser({
        data,
        onSuccess: (user) => {
          switch (user.role) {
            case "admin":
              navigate("/admin");
              break;
            case "receptionist":
              navigate("/receptionist");
              break;
            case "supervisor":
              navigate("/supervisor");
              break;
            case "mechanic":
              navigate("/mechanic");
              break;
            case "driver":
              navigate("/driver");
              break;
            default:
              navigate("/login");
              break;
          }
        },
      })
    );
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    (document.title = "Carfix Curacao | Login"),
    (
      <div className="signInBg signInBgTab signInBgMob signInBgLap z-10">
        <div className="flex items-start justify-start h-32 px-8">
          <img
            src={logo}
            alt="Logo"
            style={{ width: "auto", height: "100%", objectFit: "contain" }}
          />
        </div>
        <div className="bg-calc flex items-center px-16 pb-20 sm:w-3/4 lg:w-1/3">
          <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <Typography
              className="poppins-bold mb-10"
              style={{ fontSize: 48, color: theme.palette.text.darkBlue }}
            >
              Sign In
            </Typography>

            <div className="flex flex-col">
              <Typography className="mb-2" style={{ fontSize: 14 }}>
                Email
              </Typography>
              <CarfixInput
                placeholder="abc@abc.com"
                type="email"
                className="mb-5"
                error={Boolean(errors?.email)}
                helperText={errors?.email?.message}
                {...register("email", {
                  required: "Email is required",
                })}
              />
              <Typography className="mb-2" style={{ fontSize: 14 }}>
                Password
              </Typography>
              <CarfixInput
                type={showPassword ? "text" : "password"}
                placeholder="********"
                error={Boolean(errors?.password)}
                helperText={errors?.password?.message}
                {...register("password", {
                  required: "Password is required",
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div className="flex mt-5 items-center">
                <Checkbox {...register("remember")} />{" "}
                <Typography>Remember Me</Typography>
              </div>
              <div className="mt-12">
                <button
                  type="submit"
                  className="h-16 w-16 client-btn justify-center items-center flex"
                  style={{ borderRadius: 28 }}
                >
                  <img src={nextImg} alt="Submit" />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  );
};
