import React from "react";
import logo from "../../assets/images/logo.svg";
import { useNavigate } from "react-router-dom";

export const ClientLayout = ({ children }) => {
  const navigate = useNavigate();
  return (
    <div className="lg:flex lg:justify-center">
      <div className="min-h-screen lg:w-2/5">
        <div className="flex items-start justify-start max-h-32 h-24 sm:h-32 sm:px-9">
          <img
            className="cursor-pointer sm:h-full h-4/5"
            onClick={() => navigate("/")}
            src={logo}
            alt="Logo"
            style={{ width: "auto", objectFit: "contain" }}
          />
        </div>
        <div className="flex-grow">
          <main>{children}</main>
        </div>
      </div>
    </div>
  );
};
