import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CursorOrderImg from "../../assets/images/close.svg";
import { HeadingwithData } from "../modalComponents/HeadingwithData";
import { MediaWithHeading } from "../modalComponents/MediaWithHeading";
import { OrderRemarks } from "../modalComponents/OrderRemarks";
import ShowMedia from "../modalComponents/ShowMedia";
import saveFloppy from "../../assets/images/saveFloppy.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  addMedia,
  addRemarks,
  completedByDriver,
  getDriverJob,
} from "../../redux-slices/jobsSlice";
import { DriverModalTable } from "./DriverModalTable";
import { DriverOrderData } from "./DriverOrderData";
import CarfixInput from "../commonComponents/CarfixInput";
import {
  addMediaToGeneralPickupOrder,
  addRemarksToGeneralPickupOrder,
  cancelGeneralPickupOrder,
  completeGeneralPickupOrder,
} from "../../redux-slices/generalPickupOrderSlice";
import UploadMedia from "../modalComponents/UploadMedia";
import PrintImg from "../../assets/images/PrintOrderImg.png";
import { getPdf } from "../../redux-slices/pdfSlice";

const DriverModalDetails = ({ id, tab, closeModal, fetchData }) => {
  const headerTitles = [
    {
      id: 3,
      title: "Location",
    },
    {
      id: 0,
      title: "Name",
    },
    {
      id: 1,
      title: "Qty",
    },
    {
      id: 2,
      title: "Status",
    },
  ];

  const theme = useTheme();
  const dispatch = useDispatch();
  const { jobModalOpen } = useSelector((state) => state.modals);
  const { job } = useSelector((state) => state.jobs);
  const { user } = useSelector((state) => state.auth);

  const [uploadLoading, setUploadLoading] = useState(false);
  const [media, setMedia] = useState([]);
  const [remarks, setRemarks] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (media.length > 0) {
      submitMedia();
    }
    // eslint-disable-next-line
  }, [media]);

  const fetchJobData = (setLoading) => {
    dispatch(getDriverJob({ id, tab })).then(() => {
      if (setLoading) setLoading(false);
    });
  };

  useEffect(() => {
    if (id && jobModalOpen) {
      setLoading(true);
      fetchJobData(setLoading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, tab, jobModalOpen]);

  const submitMedia = () => {
    const formData = new FormData();
    media.forEach((file, index) => {
      formData.append(`media[${index}]`, file);
    });

    setUploadLoading(true);

    if (tab === 0) {
      formData.append("job_id", id);
      dispatch(
        addMedia({
          data: formData,
          onSuccess: () => {
            setMedia([]);
            fetchJobData(setLoading);
            setUploadLoading(false);
          },
        })
      );
    } else {
      formData.append("id", id);
      dispatch(
        addMediaToGeneralPickupOrder({
          data: formData,
          onSuccess: () => {
            setMedia([]);
            fetchJobData(setLoading);
            setUploadLoading(false);
          },
        })
      );
    }
  };

  const handleComplete = () => {
    if (remarks !== "") {
      onRemarksSubmit({ remarks });
    }
    if (tab === 0) {
      dispatch(
        completedByDriver({
          id,
          onSuccess: () => {
            fetchData();
            closeModal();
          },
        })
      );
    } else {
      dispatch(
        completeGeneralPickupOrder({
          id,
          onSuccess: () => {
            fetchData();
            closeModal();
          },
        })
      );
    }
  };

  const handleCancelPickupOrder = () => {
    if (remarks !== "") {
      onRemarksSubmit({ remarks });
    }
    dispatch(
      cancelGeneralPickupOrder({
        id,
        onSuccess: () => {
          fetchData();
          closeModal();
        },
      })
    );
  };

  const onRemarksSubmit = (data) => {
    if (remarks === "") return;
    setUploadLoading(true);

    if (tab === 0) {
      data.job_id = id;
      dispatch(
        addRemarks({
          data: data,
          onSuccess: () => {
            setRemarks("");
            fetchJobData(setUploadLoading);
          },
        })
      );
    } else {
      data.id = id;
      dispatch(
        addRemarksToGeneralPickupOrder({
          data: data,
          onSuccess: () => {
            setRemarks("");
            fetchJobData(setUploadLoading);
          },
        })
      );
    }
  };

  const handlePrint = () => {
    dispatch(getPdf(id));
  };

  return (
    <Dialog
      open={jobModalOpen}
      onClose={closeModal}
      maxWidth="xl"
      scroll="paper"
      PaperProps={{
        style: {
          borderRadius: 30,
          padding: 10,
          minWidth: "60%",
        },
      }}
    >
      <DialogTitle id="scroll-dialog-title">
        <div className="flex justify-between">
          <div className="flex">
            <Typography
              className="text-3xl font-bold"
              style={{ color: theme.palette.blue.main }}
            >
              {loading
                ? "Pick-Up"
                : job?.display_id
                ? `Pick-Up# ${job?.display_id}`
                : `Pick-Up# ${job?.id}`}
            </Typography>
            <div className="flex justify-center items-center ml-5">
              <img
                src={PrintImg}
                alt="PrintImg"
                className="cursor-pointer"
                onClick={handlePrint}
              />
            </div>
          </div>
          <img
            src={CursorOrderImg}
            alt="CursorOrderImg"
            className="cursor-pointer"
            onClick={closeModal}
          />
        </div>
      </DialogTitle>

      <DialogContent className="mr-7 pl-7">
        {loading ? (
          <div className="h-60 w-96">
            <CircularProgress
              sx={{ color: theme.palette.orange.main }}
              className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
            />
          </div>
        ) : (
          <div className="orderModal justify-between">
            <div className="flex flex-col w-full 2xl:w-1/2">
              <DriverOrderData tab={tab} data={job} />

              <HeadingwithData
                heading={tab === 0 ? "Service/Repair Required" : "Description"}
                data={job?.description || "N/A"}
              />
              {job?.remarks?.length > 0 && (
                <div>
                  <Typography
                    className="font-semibold text-xl mb-3 mt-4"
                    style={{ color: theme.palette.blue.main }}
                  >
                    Remarks
                  </Typography>
                  <OrderRemarks remarks={job?.remarks} />
                </div>
              )}

              <div
                className={`w-11/12 relative z-0 ${
                  job?.remarks?.length === 0 ? "mt-5" : ""
                }`}
              >
                <div className="flex justify-between pr-1">
                  <Typography
                    className="md:mt-0 text-sm font-bold mb-2"
                    style={{ color: "#F3A366", fontSize: 14 }}
                  >
                    Add Your Remarks Below :
                  </Typography>
                  <img
                    src={saveFloppy}
                    alt="save"
                    className="cursor-pointer"
                    onClick={() => onRemarksSubmit({ remarks })}
                  />
                </div>
                <CarfixInput
                  id="remarks-input"
                  className="text-sm font-normal w-full"
                  onChange={(e) => setRemarks(e.target.value)}
                  value={remarks}
                  multiline
                  rows={4}
                  placeholder="Remarks"
                />
              </div>
            </div>
            <div className="flex flex-col w-full 2xl:w-1/2">
              <Typography
                className="text-sm"
                style={{ color: theme.palette.orange.main }}
              >
                Pick up Requests
              </Typography>
              <DriverModalTable
                tab={tab}
                headerTitles={headerTitles}
                data={job?.parts_by_garage ?? job?.pickup_items}
                fetchJobData={fetchJobData}
              />
              {job?.media_by_customer?.length > 0 && (
                <div className="mb-5 mt-5">
                  <MediaWithHeading
                    heading={"Client"}
                    source={job?.media_by_customer}
                  />
                </div>
              )}
              <div>
                <ShowMedia
                  media={job?.media_by_garage ?? job?.media}
                  userRole={user?.roles?.[0]?.name}
                  fetchJobData={fetchJobData}
                />
              </div>
              <div>
                <UploadMedia media={media} setMedia={setMedia} />
              </div>
            </div>
          </div>
        )}
        {uploadLoading && (
          <div>
            <CircularProgress
              sx={{ color: theme.palette.orange.main }}
              className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {user.roles[0].name === "driver" && (
          <Button
            className="h-10 md:w-36 text-white mt-7 md:mr-5 rounded-lg text-base"
            style={{
              textTransform: "none",
              background: theme.palette.orange.main,
            }}
            onClick={handleComplete}
          >
            Complete
          </Button>
        )}
        {user.roles[0].name !== "driver" && job?.status === "pending" && (
          <Button
            className="h-10 md:w-36 text-white mt-7 md:mr-5 rounded-lg text-base"
            style={{
              textTransform: "none",
              background: theme.palette.red.main,
            }}
            onClick={handleCancelPickupOrder}
          >
            Cancel Order
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DriverModalDetails;
