import React from "react";
import { Typography } from "@mui/material";
import CarfixInput from "../commonComponents/CarfixInput";
import saveFloppy from "../../assets/images/saveFloppy.svg";

export const AddRemarks = ({ remarks, setRemarks, submitRemarks }) => {
  return (
    <div className="w-11/12 relative z-0">
      <div className="flex justify-between pr-0.5">
        <Typography
          className="md:mt-0 text-sm font-bold mb-2"
          style={{ color: "#F3A366", fontSize: 14 }}
        >
          Add Your Remarks Below :
        </Typography>
        <img
          src={saveFloppy}
          alt="save"
          className="cursor-pointer"
          onClick={() => submitRemarks()}
        />
      </div>
      <CarfixInput
        id="remarks-input"
        className="text-sm font-normal w-full"
        onChange={(e) => setRemarks(e.target.value)}
        value={remarks}
        multiline
        rows={4}
        placeholder="Remarks"
      />
    </div>
  );
};
