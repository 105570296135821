import React from "react";
import Typography from "@mui/material/Typography";
import { Gallery } from "./Gallery";
import { capitalize } from "@mui/material";

export const MediaWithHeading = ({
  userRole,
  heading,
  source,
  fetchJobData,
}) => {
  return (
    <div className="flex flex-col">
      <Typography
        className="md:pr-28 mb-2"
        style={{ fontSize: 14, color: "#F3A366" }}
      >
        {capitalize(heading)} Media
      </Typography>
      <Gallery
        showDelete={userRole === heading}
        itemData={source}
        fetchJobData={fetchJobData}
      />
    </div>
  );
};
