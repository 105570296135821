import { Grid, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { formatStatus } from "../../utils/helpers";
import editImg from "../../assets/images/edit.svg";
import saveImg from "../../assets/images/saveTick.svg";
import {
  updateChassisNumber,
  updateMileage,
} from "../../redux-slices/jobsSlice";
import { useDispatch } from "react-redux";

export const OrderData = ({
  data,
  showUserData = false,
  editable = false,
  fetchJobData,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [chassis_number, setChassis_number] = useState(
    data?.vehicle?.chassis_number
  );
  const [mileage, setMileage] = useState(data?.vehicle?.mileage);
  const [mileage_unit, setMileage_unit] = useState(data?.vehicle?.mileage_unit);

  const [editChassis, setEditChassis] = useState(false);
  const [editMileage, setEditMileage] = useState(false);

  const handleChassisSave = () => {
    if (chassis_number !== data?.vehicle?.chassis_number) {
      dispatch(
        updateChassisNumber({
          id: data?.vehicle?.id,
          job_id: data?.id,
          chassis_number,
          onSuccess: () => fetchJobData(),
        })
      );
    }
    setEditChassis(false);
  };

  const handleMileageSave = () => {
    if (
      // eslint-disable-next-line eqeqeq
      mileage != data?.vehicle?.mileage ||
      // eslint-disable-next-line eqeqeq
      mileage_unit != data?.vehicle?.mileage_unit
    ) {
      dispatch(
        updateMileage({
          id: data?.vehicle?.id,
          job_id: data?.id,
          mileage,
          mileage_unit,
          onSuccess: () => fetchJobData(),
        })
      );
    }
    setEditMileage(false);
  };

  return (
    <Grid container>
      {showUserData && (
        <>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Typography
              className="text-sm font-medium mb-1"
              style={{ color: theme.palette.orange.main }}
            >
              Name
            </Typography>
            <Typography
              className="text-sm font-normal mb-4"
              style={{ color: theme.palette.blue.main }}
            >
              {data?.customer?.user?.name || ""}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Typography
              className="text-sm font-medium mb-1"
              style={{ color: theme.palette.orange.main }}
            >
              Email
            </Typography>
            <Typography
              className="text-sm font-normal mb-4"
              style={{ color: theme.palette.blue.main }}
            >
              {data?.customer?.user?.email || ""}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Typography
              className="text-sm font-medium mb-1"
              style={{ color: theme.palette.orange.main }}
            >
              Phone Number
            </Typography>
            <Typography
              className="text-sm font-normal mb-4"
              style={{ color: theme.palette.blue.main }}
            >
              {data?.customer?.phone_num || ""}
            </Typography>
          </Grid>
        </>
      )}
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <Typography
          className="text-sm font-medium mb-1"
          style={{ color: theme.palette.orange.main }}
        >
          Status
        </Typography>
        <Typography
          className="text-sm font-normal mb-4"
          style={{ color: theme.palette.blue.main }}
        >
          <span>{formatStatus(data?.status)}</span>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <Typography
          className="text-sm font-medium mb-1"
          style={{ color: theme.palette.orange.main }}
        >
          Date & Time
        </Typography>
        <Typography
          className="text-sm font-normal mb-4"
          style={{ color: theme.palette.blue.main }}
        >
          {data?.date_time || ""}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <Typography
          className="text-sm font-medium mb-1"
          style={{ color: theme.palette.orange.main }}
        >
          Duration in Garage
        </Typography>
        <Typography
          className="text-sm font-normal mb-4"
          style={{ color: theme.palette.blue.main }}
        >
          {data?.duration_in_garage || ""}
        </Typography>
      </Grid>
      {data?.completed_at && (
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Typography
            className="text-sm font-medium mb-1"
            style={{ color: theme.palette.orange.main }}
          >
            Finish Date
          </Typography>
          <Typography
            className="text-sm font-normal mb-4"
            style={{ color: theme.palette.blue.main }}
          >
            {data.completed_at}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <Typography
          className="text-sm font-medium mb-1"
          style={{ color: theme.palette.orange.main }}
        >
          License Plate
        </Typography>
        <Typography
          className="text-sm font-normal mb-4"
          style={{ color: theme.palette.blue.main }}
        >
          {data?.vehicle?.license_number || ""}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <Typography
          className="text-sm font-medium mb-1"
          style={{ color: theme.palette.orange.main }}
        >
          Make & Model
        </Typography>
        <Typography
          className="text-sm font-normal mb-4"
          style={{ color: theme.palette.blue.main }}
        >
          {data?.vehicle?.make_and_model || ""}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <Typography
          className="text-sm font-medium mb-1"
          style={{ color: theme.palette.orange.main }}
        >
          Year
        </Typography>
        <Typography
          className="text-sm font-normal mb-4"
          style={{ color: theme.palette.blue.main }}
        >
          {data?.vehicle?.year || ""}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <div className="w-3/5 flex justify-between items-center">
          <Typography
            className="text-sm font-medium mb-1"
            style={{ color: theme.palette.orange.main }}
          >
            Vin/Chassis #
          </Typography>
          {editable && (
            <img
              src={editChassis ? saveImg : editImg}
              onClick={
                editChassis ? handleChassisSave : () => setEditChassis(true)
              }
              alt="edit"
              className="cursor-pointer w-3 h-3"
            />
          )}
        </div>
        <Typography
          className="text-sm font-normal mb-4"
          style={{ color: theme.palette.blue.main }}
        >
          {editChassis ? (
            <input
              type="text"
              value={chassis_number}
              onChange={(e) => setChassis_number(e.target.value)}
              className="w-2/3 border-gray-200 border rounded-md p-1"
            />
          ) : (
            chassis_number ?? "-"
          )}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <div className="w-2/5 flex justify-between items-center">
          <Typography
            className="text-sm font-medium mb-1"
            style={{ color: theme.palette.orange.main }}
          >
            Odometer
          </Typography>
          {editable && (
            <img
              src={editMileage ? saveImg : editImg}
              onClick={
                editMileage ? handleMileageSave : () => setEditMileage(true)
              }
              alt="edit"
              className="cursor-pointer w-3 h-3"
            />
          )}
        </div>
        <Typography
          className="text-sm font-normal mb-4"
          style={{ color: theme.palette.blue.main }}
        >
          {editMileage ? (
            <div className="flex w-2/3 border-gray-200 border rounded-md p-1">
              <input
                type="number"
                value={mileage}
                onChange={(e) => setMileage(e.target.value)}
                className="w-full"
              />
              <select
                value={mileage_unit}
                onChange={(e) => setMileage_unit(e.target.value)}
              >
                <option value="km">km</option>
                <option value="mi">mi</option>
              </select>
            </div>
          ) : mileage ? (
            `${mileage} ${mileage_unit}`
          ) : (
            "-"
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};
