import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CursorOrderImg from "../../assets/images/close.svg";
import { OrderData } from "../modalComponents/OrderData";
import { HeadingwithData } from "../modalComponents/HeadingwithData";
import { MediaWithHeading } from "../modalComponents/MediaWithHeading";
import { ReceptionistModalTable } from "./ReceptionistModalTable";
import { OrderRemarks } from "../modalComponents/OrderRemarks";
import { AddRemarks } from "../modalComponents/AddRemarks";
import ShowMedia from "../modalComponents/ShowMedia";
import { useDispatch, useSelector } from "react-redux";
import ReceptionistButtons from "./ReceptionistsModalButtons";
import { handleMediaSubmit, handleRemarksSubmit } from "../../utils/helpers";
import UploadMedia from "../modalComponents/UploadMedia";
import PrintImg from "../../assets/images/PrintOrderImg.png";
import { getPdf } from "../../redux-slices/pdfSlice";

const ReceptionistModalDetails = ({
  jobModalOpen,
  closeModal,
  loading,
  setLoading,
  job,
  tab,
  fetchJobData,
  id,
  fetchData,
}) => {
  const headerTitles = [
    {
      id: 3,
      title: "Location",
    },
    {
      id: 0,
      title: "Name",
    },
    {
      id: 1,
      title: "Qty",
    },
    {
      id: 2,
      title: "Status",
    },
  ];

  const theme = useTheme();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const [media, setMedia] = useState([]);
  const [remarks, setRemarks] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);

  const submitMedia = () => {
    handleMediaSubmit(
      media,
      id,
      setMedia,
      fetchJobData,
      setLoading,
      setUploadLoading,
      dispatch
    );
  };

  const submitRemarks = () => {
    handleRemarksSubmit(
      remarks,
      id,
      setRemarks,
      fetchJobData,
      setLoading,
      setUploadLoading,
      dispatch
    );
  };

  const handlePrint = () => {
    dispatch(getPdf(id));
  };

  useEffect(() => {
    if (media.length > 0) {
      submitMedia();
    }
    // eslint-disable-next-line
  }, [media]);

  return (
    <Dialog
      open={jobModalOpen}
      onClose={closeModal}
      maxWidth="xl"
      scroll="paper"
      PaperProps={{
        style: {
          borderRadius: 30,
          padding: 10,
        },
      }}
    >
      <DialogTitle id="scroll-dialog-title">
        <div className="flex justify-between">
          <div className="flex">
            <Typography
              className="text-3xl font-bold"
              style={{ color: theme.palette.blue.main }}
            >
              {loading ? "Service Order" : `Service Order ${job?.display_id}`}
            </Typography>
            <div className="flex justify-center items-center ml-5">
              <img
                src={PrintImg}
                alt="PrintImg"
                className="cursor-pointer"
                onClick={handlePrint}
              />
            </div>
          </div>
          <img
            src={CursorOrderImg}
            alt="CursorOrderImg"
            className="cursor-pointer"
            onClick={closeModal}
          />
        </div>
      </DialogTitle>

      <DialogContent className="mr-7 pl-7">
        {loading ? (
          <div className="h-60 w-96">
            <CircularProgress
              sx={{ color: theme.palette.orange.main }}
              className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
            />
          </div>
        ) : (
          <div className="orderModal justify-between">
            <div className="flex flex-col w-full 2xl:w-1/2">
              <OrderData
                editable
                showUserData
                fetchJobData={fetchJobData}
                data={job}
              />

              <HeadingwithData
                heading={"Service/Repair Required"}
                data={job?.description}
              />
              {job?.remarks?.length > 0 && (
                <div>
                  <Typography
                    className="font-semibold text-xl mb-3 mt-4"
                    style={{ color: theme.palette.blue.main }}
                  >
                    Remarks
                  </Typography>
                  <OrderRemarks remarks={job?.remarks} />
                </div>
              )}
              {(tab === 0 || tab === 1) && (
                <div className={job?.remarks?.length === 0 ? "mt-5" : ""}>
                  <AddRemarks
                    remarks={remarks}
                    setRemarks={setRemarks}
                    submitRemarks={submitRemarks}
                  />
                </div>
              )}
            </div>
            <div className="flex flex-col w-full 2xl:w-1/2">
              {(tab === 0 ||
                (tab === 1 && job?.status === "to-be-assigned") ||
                job?.parts_by_customer?.length > 0) && (
                <>
                  <Typography
                    className="text-sm"
                    style={{ color: theme.palette.orange.main }}
                  >
                    Parts Provided By User
                  </Typography>
                  <ReceptionistModalTable
                    tab={tab}
                    headerTitles={headerTitles}
                    data={job?.parts_by_customer}
                    job_id={job?.id}
                    fetchJobData={fetchJobData}
                    status={job?.status}
                    user_id={job?.customer?.user_id}
                    partBy={"customer"}
                  />
                </>
              )}

              {(tab === 0 ||
                (tab === 1 && job?.status === "to-be-assigned") ||
                job?.parts_by_garage?.length > 0) && (
                <>
                  <Typography
                    className="text-sm"
                    style={{ color: theme.palette.orange.main }}
                  >
                    Pick up Requests
                  </Typography>
                  <ReceptionistModalTable
                    tab={tab}
                    headerTitles={headerTitles}
                    data={job?.parts_by_garage}
                    job_id={job?.id}
                    fetchJobData={fetchJobData}
                    status={job?.status}
                    user_id={user.id}
                    partBy={"garage"}
                  />
                </>
              )}
              {job?.media_by_customer?.length > 0 && (
                <div className="mb-5 mt-5">
                  <MediaWithHeading
                    heading={"Client"}
                    source={job?.media_by_customer}
                  />
                </div>
              )}
              <div className="mt-5">
                <ShowMedia
                  userRole={user?.roles?.[0]?.name}
                  media={job?.media_by_garage}
                  fetchJobData={fetchJobData}
                />
              </div>
              {(tab === 0 || tab === 1) && (
                <div>
                  <UploadMedia media={media} setMedia={setMedia} />
                </div>
              )}
            </div>
          </div>
        )}
        {uploadLoading && (
          <div>
            <CircularProgress
              sx={{ color: theme.palette.orange.main }}
              className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <ReceptionistButtons
          id={id}
          tab={tab}
          status={job?.status}
          escalated={job?.job_escalated}
          remarks={remarks}
          submitRemarks={submitRemarks}
          fetchData={fetchData}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ReceptionistModalDetails;
