import React from "react";
import { Tabs, Tab, useTheme } from "@mui/material";
import { useSelector } from "react-redux";

export const NavButtons = ({ tabData, currTab, setTab }) => {
  const theme = useTheme();
  const { jobs } = useSelector((state) => state.jobs);
  const jobCounts = jobs?.[1];

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Tabs
      className="border-b-2"
      value={currTab}
      onChange={handleChange}
      aria-label="navigation tabs"
      indicatorColor="primary"
      textColor="inherit"
      variant="scrollable"
      scrollButtons="auto"
    >
      {tabData?.map((item) => (
        <Tab
          className="mx-1 w-42 text-sm"
          key={item.id}
          label={
            <span>
              {item.title}
              {jobCounts && (
                <strong style={{ marginLeft: "5px" }}>
                  {` (${jobCounts[item.val]})`}
                </strong>
              )}
            </span>
          }
          value={item.id}
          sx={{
            textTransform: "none",
            backgroundColor:
              currTab === item.id
                ? theme.palette.orange.main
                : theme.palette.blue.main,
            color:
              currTab === item.id
                ? theme.palette.blue.main
                : theme.palette.orange.main,
            borderTopRightRadius: "10px",
          }}
        />
      ))}
    </Tabs>
  );
};
