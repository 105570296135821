import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

export const getGeneralPickupOrders = createAsyncThunk(
  "jobsSlice/getGeneralPickupOrders",
  async (data, { rejectWithValue }) => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get-general-pickup-orders`,
        { params: data }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createGeneralPickupOrder = createAsyncThunk(
  "jobsSlice/createGeneralPickupOrder",
  async ({ data, onSuccess }, { rejectWithValue }) => {
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/create-general-pickup-order`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const cancelGeneralPickupOrder = createAsyncThunk(
  "jobsSlice/cancelGeneralPickupOrder",
  async ({ id, onSuccess }, { rejectWithValue }) => {
    try {
      let response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/cancel-general-pickup-order`,
        { id }
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addRemarksToGeneralPickupOrder = createAsyncThunk(
  "jobsSlice/addRemarksToGeneralPickupOrder",
  async ({ data, onSuccess }, { rejectWithValue }) => {
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/add-remarks-to-general-pickup-order`,
        data
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addMediaToGeneralPickupOrder = createAsyncThunk(
  "jobsSlice/addMediaToGeneralPickupOrder",
  async ({ data, onSuccess }, { rejectWithValue }) => {
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/add-media-to-general-pickup-order`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateItemStatus = createAsyncThunk(
  "jobsSlice/updateItemStatus",
  async ({ id, data, onSuccess }, { rejectWithValue }) => {
    try {
      let response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/update-item-status/${id}`,
        data
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const completeGeneralPickupOrder = createAsyncThunk(
  "jobsSlice/completeGeneralPickupOrder",
  async ({ id, onSuccess }, { rejectWithValue }) => {
    try {
      let response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/complete-general-pickup-order`,
        { id }
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const generalPickupSlice = createSlice({
  name: "generalPickupOrder",
  initialState: {
    generalPickupOrders: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGeneralPickupOrders.fulfilled, (state, action) => {
      state.generalPickupOrders = action.payload?.data;
    });
    builder.addCase(getGeneralPickupOrders.rejected, (state, action) => {
      toast.error(
        action.payload?.message ?? "Failed to fetch general pickup orders"
      );
    });

    builder.addCase(createGeneralPickupOrder.fulfilled, (state, action) => {
      toast.success(
        action.payload?.message ?? "General pickup order created successfully"
      );
    });

    builder.addCase(createGeneralPickupOrder.rejected, (state, action) => {
      toast.error(
        action.payload?.message ?? "Failed to create general pickup order"
      );
    });

    builder.addCase(cancelGeneralPickupOrder.fulfilled, (state, action) => {
      toast.success(
        action.payload?.message ?? "General pickup order cancelled successfully"
      );
    });
    builder.addCase(cancelGeneralPickupOrder.rejected, (state, action) => {
      toast.error(
        action.payload?.message ?? "Failed to cancel general pickup order"
      );
    });

    builder.addCase(
      addRemarksToGeneralPickupOrder.fulfilled,
      (state, action) => {
        toast.success(action.payload?.message ?? "Remarks added successfully");
      }
    );
    builder.addCase(
      addRemarksToGeneralPickupOrder.rejected,
      (state, action) => {
        toast.error(action.payload?.message ?? "Failed to add remarks");
      }
    );

    builder.addCase(addMediaToGeneralPickupOrder.fulfilled, (state, action) => {
      toast.success(action.payload?.message ?? "Media added successfully");
    });
    builder.addCase(addMediaToGeneralPickupOrder.rejected, (state, action) => {
      toast.error(action.payload?.message ?? "Failed to add media");
    });

    builder.addCase(updateItemStatus.fulfilled, (state, action) => {
      toast.success(action.payload?.message ?? "Status updated successfully");
    });
    builder.addCase(updateItemStatus.rejected, (state, action) => {
      toast.error(action.payload?.message ?? "Failed to update status");
    });

    builder.addCase(completeGeneralPickupOrder.fulfilled, (state, action) => {
      toast.success(
        action.payload?.message ?? "General pickup order completed successfully"
      );
    });
    builder.addCase(completeGeneralPickupOrder.rejected, (state, action) => {
      toast.error(
        action.payload?.message ?? "Failed to complete general pickup order"
      );
    });
  },
});

export const {} = generalPickupSlice.actions;
export default generalPickupSlice.reducer;
