import { Button } from "@mui/material";
import { Link, useRouteError } from "react-router-dom";

export default function ErrorPage({ title }) {
  const error = useRouteError();
  console.error(error);

  return (
    <div
      id="error-page"
      className=" flex flex-col items-center justify-center content-center mt-32"
    >
      <h1>Oops!</h1>
      {title ? <h2>{title}</h2> : <h2>Something went wrong</h2>}
      {!title && (
        <p>
          <i>{error.statusText || error.message}</i>
        </p>
      )}
      {title ? (
        <Link to={"/login"}>
          <Button variant="contained" className="mt-10 rounded-lg p-3">
            Go Back Login
          </Button>
        </Link>
      ) : (
        <Link to={"/"}>
          <Button variant="contained" className="mt-10 rounded-lg p-3">
            Go Back Home Page
          </Button>
        </Link>
      )}
    </div>
  );
}
