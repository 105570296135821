import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { closeJobModal, openJobModal } from "../../redux-slices/modalSlice";
import {
  StyledTableCell,
  StyledTableRow,
} from "../commonComponents/StaffTable";
import dayjs from "dayjs";
import DriverModalDetails from "./DriverModalDetails";
import { formatStatus, getStatusColor } from "../../utils/helpers";
import CreatePickupOrderModal from "../commonComponents/CreatePickupOrderModal";
import arrowdown from "../../assets/images/arrowDownWhite.svg";
import CustomPopover from "../commonComponents/CustomerPopover";
import PrintImg from "../../assets/images/PrintOrderImg.png";
import { getPickupOrdersPdf } from "../../redux-slices/pdfSlice";

export const DriverTable = ({
  jobs = [],
  headerTitles,
  sort,
  setSorting,
  setStatusFilter,
  fetchData,
  setLimit,
  tab,
  showPickupNumber,
  showServiceOrder,
  showMakeAndModel,
  showAssignedBy,
  showAssignedTo,
  showDescription,
  showStatus = false,
}) => {
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [id, setId] = useState(null);
  const [headerPopOver, setHeaderPopOver] = useState(false);
  const [headerPopOverAnchorEl, setHeaderPopOverAnchorEl] = useState(null);
  const [showPickupOrderModal, setShowPickupOrderModal] = useState(false);

  const openModal = (row) => {
    setId(row.job_id ?? row.id);
    dispatch(openJobModal());
  };

  const closeModal = () => {
    dispatch(closeJobModal());
  };

  const handleArrowDownClick = (e, header) => {
    const target = e.target;
    if (header.val === "status") {
      setHeaderPopOverAnchorEl(target);
      setHeaderPopOver(true);
    } else {
      target.classList.toggle("rotate-180");
      setSorting(header.id);
    }
  };

  const popoverOptions = [
    { title: "All", val: "any" },
    { title: "Pending", val: "pending" },
    { title: "Completed", val: "completed" },
    { title: "Cancelled", val: "cancelled" },
  ];

  const handleHeaderPopOverOptionClick = (option) => {
    setStatusFilter(option.val);
    setHeaderPopOver(false);
  };

  const handlePickupOrdersPrint = () => {
    dispatch(getPickupOrdersPdf(tab));
  };

  return (
    <div className="mx-7 md:mx-10">
      <div
        className={`flex items-center mb-5 py-0 ${
          user.roles[0].name !== "driver" ? "justify-between" : ""
        }`}
      >
        <Typography
          className="font-bold text-3xl"
          style={{ color: theme.palette.blue.main }}
        >
          Pickup Orders
        </Typography>
        {user.roles[0].name !== "driver" && (
          <div className="w-48">
            <Button
              className="w-full h-12 text-white rounded-xl text-base"
              style={{
                background: theme.palette.orange.main,
                textTransform: "none",
              }}
              onClick={() => setShowPickupOrderModal(true)}
            >
              Create Pickup Order
            </Button>
          </div>
        )}
        {user.roles[0].name === "driver" && (
          <div className="ml-4">
            <img
              src={PrintImg}
              alt="PrintImg"
              className="cursor-pointer"
              onClick={handlePickupOrdersPrint}
            />
          </div>
        )}
      </div>
      <div className="mb-10">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }}>
            <TableHead>
              <TableRow>
                {headerTitles?.map((header) => (
                  <StyledTableCell
                    className={`
                       ${header.id === 6 ? "w-60" : "w-28"}
                       ${!header.show && "hidden"}
                       `}
                    key={header.id}
                  >
                    {header.id === 6 ? (
                      <div className="flex justify-between items-center h-5 w-full px-1">
                        <div style={{ width: "30%" }}>
                          <Typography className="text-white text-xs">
                            Name
                          </Typography>
                        </div>
                        <div style={{ width: "10%" }}>
                          <Typography className="text-white text-xs">
                            Qty
                          </Typography>
                        </div>
                        <div style={{ width: "40%" }}>
                          <Typography className="text-white text-xs">
                            Location
                          </Typography>
                        </div>
                        <div style={{ width: "20%" }}>
                          <Typography className="text-white text-xs text-center">
                            Status
                          </Typography>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`flex ${
                          header.title === "Status"
                            ? "justify-start pl-8"
                            : "justify-center"
                        }`}
                      >
                        {header.title}
                        {header.img && (
                          <img
                            src={arrowdown}
                            className={`ml-2 cursor-pointer ${
                              sort.includes(header.val) ? "rotate-180" : ""
                            }`}
                            alt=""
                            onClick={(e) => handleArrowDownClick(e, header)}
                          />
                        )}
                      </div>
                    )}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {jobs?.[0]?.map((row) => (
                <StyledTableRow
                  key={row.id}
                  onClick={() => {
                    openModal(row);
                  }}
                  className="cursor-pointer"
                >
                  {showPickupNumber && (
                    <StyledTableCell component="td" scope="row" align="center">
                      {row.id}
                    </StyledTableCell>
                  )}
                  {showServiceOrder && (
                    <StyledTableCell align="center">
                      {row.display_id ?? "N/A"}
                    </StyledTableCell>
                  )}
                  <StyledTableCell align="center">
                    {dayjs(row.date_time).format("YYYY:MM:DD HH:mm") || "N/A"}
                  </StyledTableCell>
                  {showMakeAndModel && (
                    <StyledTableCell align="center">
                      {row.make_and_model ?? "N/A"}
                    </StyledTableCell>
                  )}
                  <StyledTableCell align="center">
                    {showDescription ? row.description : row.chassis_number}
                  </StyledTableCell>
                  {showAssignedBy && (
                    <StyledTableCell align="center">
                      {row.assigned_by ?? "N/A"}
                    </StyledTableCell>
                  )}
                  {showAssignedTo && (
                    <StyledTableCell align="center">
                      {row.assigned_to ?? "N/A"}
                    </StyledTableCell>
                  )}
                  {showStatus && (
                    <StyledTableCell>
                      <div className="flex items-center">
                        <div>
                          <div
                            className="w-2 h-2 mr-4 rounded-full"
                            style={{
                              backgroundColor: getStatusColor(row.status),
                            }}
                          />
                        </div>
                        <span>{formatStatus(row.status)}</span>
                      </div>
                    </StyledTableCell>
                  )}
                  <StyledTableCell className="p-0 w-48">
                    {row.parts?.map((part) => (
                      <div
                        key={part.id}
                        className="flex justify-between items-center w-full px-5 py-3"
                      >
                        <div style={{ width: "30%" }}>
                          <Typography
                            style={{ color: theme.palette.blue.main }}
                          >
                            {part.name || "N/A"}
                          </Typography>
                        </div>
                        <div style={{ width: "10%" }}>
                          <Typography
                            style={{ color: theme.palette.blue.main }}
                          >
                            {part.quantity}
                          </Typography>
                        </div>
                        <div style={{ width: "40%" }}>
                          <Typography
                            style={{ color: theme.palette.blue.main }}
                          >
                            {part.location || "N/A"}
                          </Typography>
                        </div>
                        <div className="text-center" style={{ width: "20%" }}>
                          <Typography
                            style={{ color: theme.palette.blue.main }}
                          >
                            {part.status || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    ))}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              {(!jobs || jobs?.[0].length < 1) && (
                <StyledTableRow>
                  <StyledTableCell colSpan={headerTitles.length} align="center">
                    No data available
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {jobs?.[0]?.length < jobs?.[1] && (
          <div className="flex justify-end mt-2">
            <Typography
              className="font-bold text-base cursor-pointer"
              style={{ color: theme.palette.blue.main }}
              onClick={() => setLimit((prev) => prev * 2)}
            >
              See more {">>"}
            </Typography>
          </div>
        )}
      </div>

      <div>
        <CustomPopover
          openPopover={headerPopOver}
          setOpenPopover={setHeaderPopOver}
          anchorEl={headerPopOverAnchorEl}
          popoverOptions={popoverOptions}
          handleAction={handleHeaderPopOverOptionClick}
        />
      </div>

      <DriverModalDetails
        id={id}
        tab={tab}
        closeModal={closeModal}
        fetchData={fetchData}
      />
      <CreatePickupOrderModal
        open={showPickupOrderModal}
        handleClose={() => setShowPickupOrderModal(false)}
        fetchData={fetchData}
      />
    </div>
  );
};
