import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography, useTheme } from "@mui/material";
import { JobDetailModal } from "./JobDetailModal";
import {
  formatStatus,
  getStatusColor,
  truncateText,
} from "../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { openJobModal } from "../../redux-slices/modalSlice";
import MechanicSelectModal from "../supervisor/MechanicSelectModal";
import CustomPopover from "./CustomerPopover";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#171C36",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.text.primary,
    "& a": {
      color: "#007BFF",
      textDecoration: "underline",
    },
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const StaffTable = ({
  jobs = [],
  headerTitles,
  sort,
  tab,
  setSorting,
  fetchData,
  setAssignedToFilter,
  setLimit,
  showDuration = false,
  showDescription = false,
  showAssignedTo = false,
  showStatus = false,
}) => {
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [id, setId] = useState(null);
  const [headers, setHeaders] = useState(headerTitles);
  const [headerPopOver, setHeaderPopOver] = useState(false);
  const [headerPopOverAnchorEl, setHeaderPopOverAnchorEl] = useState(null);

  useEffect(() => {
    let newHeaders = [...headerTitles];
    if (!showDuration) {
      newHeaders = newHeaders.filter(
        (header) => header.title !== "Duration in Garage"
      );
    }
    if (!showDescription) {
      newHeaders = newHeaders.filter(
        (header) => header.title !== "Description"
      );
    }
    if (!showAssignedTo) {
      newHeaders = newHeaders.filter(
        (header) => header.title !== "Assigned To"
      );
    }
    if (!showStatus) {
      newHeaders = newHeaders.filter((header) => header.title !== "Status");
    }

    setHeaders(newHeaders);
  }, [headerTitles, showDuration, showDescription, showAssignedTo, showStatus]);

  const openModal = (row) => {
    setId(row.id);
    dispatch(openJobModal());
  };

  const handleArrowDownClick = (e, header) => {
    const target = e.target;

    if (header.val === "assigned_to") {
      setHeaderPopOverAnchorEl(target);
      setHeaderPopOver(true);
    } else {
      target.classList.toggle("rotate-180");
      setSorting(header.id);
    }
  };

  const handleHeaderPopOverOptionClick = (option) => {
    setAssignedToFilter(option.val);
    setHeaderPopOver(false);
  };

  const popoverOptions = [
    { title: "Anyone", val: "anyone" },
    { title: "Receptionist", val: "receptionist" },
    { title: "Supervisor", val: "supervisor" },
    { title: "Mechanic", val: "mechanic" },
    { title: "Driver", val: "driver" },
    { title: "None", val: "none" },
  ];

  return (
    <div className="mx-7 md:mx-10">
      <div className="flex justify-between items-center mb-5">
        <Typography
          className="font-bold text-3xl"
          style={{ color: theme.palette.blue.main }}
        >
          Service Orders
        </Typography>
      </div>
      <div className="mb-10">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }}>
            <TableHead>
              <TableRow>
                {headers?.map((header) => (
                  <StyledTableCell
                    className={header.title === "Order ID" ? "w-28" : "w-48"}
                    key={header.id}
                  >
                    <div
                      className={`flex ${
                        header.title === "Status"
                          ? "justify-start"
                          : "justify-center"
                      }`}
                    >
                      {header.title}
                      {header.img && (
                        <img
                          src={header.img}
                          className={`ml-2 cursor-pointer ${
                            sort.includes(header.val) ? "rotate-180" : ""
                          }`}
                          alt=""
                          onClick={(e) => handleArrowDownClick(e, header)}
                        />
                      )}
                    </div>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {jobs?.[0]?.map((row) => (
                <StyledTableRow
                  key={row.id}
                  onClick={() => {
                    openModal(row);
                  }}
                  className="cursor-pointer"
                >
                  <StyledTableCell component="td" scope="row" align="center">
                    {row?.display_id}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.date_time ?? "N/A"}
                  </StyledTableCell>
                  {showDuration && (
                    <StyledTableCell align="center">
                      {row.duration_in_garage ?? "N/A"}
                    </StyledTableCell>
                  )}
                  <StyledTableCell align="center">
                    {row.make_and_model ?? "Make and Model"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.license_number ?? "license number"}
                  </StyledTableCell>
                  {showDescription && (
                    <StyledTableCell align="center">
                      {truncateText(row.description, 4)}
                    </StyledTableCell>
                  )}
                  {showAssignedTo && (
                    <StyledTableCell align="center">
                      {row.assigned_to ?? "None"}
                    </StyledTableCell>
                  )}
                  {showStatus && (
                    <StyledTableCell align="center">
                      <div className="flex items-center">
                        <div>
                          <div
                            className="w-2 h-2 mr-4 rounded-full"
                            style={{
                              backgroundColor: getStatusColor(row.status),
                            }}
                          />
                        </div>
                        <span>
                          {(user.roles[0].name === "receptionist" ||
                            user.roles[0].name === "supervisor" ||
                            user.roles[0].name === "admin") &&
                          row.status === "reassigned"
                            ? "In Progress"
                            : formatStatus(row.status)}
                        </span>
                      </div>
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              ))}
              {(!jobs || jobs?.[0] < 1) && (
                <StyledTableRow>
                  <StyledTableCell colSpan={headerTitles.length} align="center">
                    No data available
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {jobs?.[0]?.length < jobs?.[1] && (
          <div className="flex justify-end mt-2">
            <Typography
              className="font-bold text-base cursor-pointer"
              style={{ color: theme.palette.blue.main }}
              onClick={() => setLimit((prev) => prev * 2)}
            >
              See more {">>"}
            </Typography>
          </div>
        )}
      </div>
      <div>
        <CustomPopover
          openPopover={headerPopOver}
          setOpenPopover={setHeaderPopOver}
          anchorEl={headerPopOverAnchorEl}
          popoverOptions={popoverOptions}
          handleAction={handleHeaderPopOverOptionClick}
        />
      </div>

      <JobDetailModal id={id} tab={tab} fetchData={fetchData} />
      <MechanicSelectModal id={id} fetchData={fetchData} />
    </div>
  );
};
