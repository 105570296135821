import { configureStore } from "@reduxjs/toolkit";
import jobs from "./redux-slices/jobsSlice";
import auth from "./redux-slices/authSlice";
import modals from "./redux-slices/modalSlice";
import users from "./redux-slices/usersSlice";
import generalPickup from "./redux-slices/generalPickupOrderSlice";

export const store = configureStore({
  reducer: {
    jobs,
    auth,
    modals,
    users,
    generalPickup,
  },
});
