import React, { useEffect, useState } from "react";
import { StaffLayout } from "../layouts/StaffLayout";
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import backImg from "../../assets/images/backArrow.svg";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../components/commonComponents/StaffTable";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { getJobLogs } from "../../redux-slices/jobsSlice";

const Logs = () => {
  const { state } = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { job_id } = state;

  const [displayJobid, setDisplayJobid] = useState(job_id);
  const [count, setCount] = useState(0);
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const getLogs = async () => {
    try {
      const result = await dispatch(getJobLogs({ id: job_id }));
      if (result.payload && result.payload.data) {
        setDisplayJobid(result.payload.data[1]);
        setCount(result.payload.data[0].length);
        setLogs(result.payload.data[0]);
      } else {
        console.error("Unexpected data structure:", result);
      }
    } catch (error) {
      console.error("Error fetching job logs:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, job_id]);

  const headers = [
    { id: "timestamp", label: "Date and Time" },
    { id: "description", label: "Description" },
  ];

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    (document.title = "Carfix Curacao | Admin"),
    (
      <StaffLayout>
        <div className="mx-7 md:mx-10">
          <div className="flex items-center py-10">
            <div className="flex justify-center" onClick={handleGoBack}>
              <img className="cursor-pointer" src={backImg} alt="back" />
            </div>
            <Typography
              className="ml-4 font-bold text-3xl"
              style={{ color: theme.palette.blue.main }}
            >
              {displayJobid} Logs
            </Typography>
          </div>

          {logs.length > 0 && (
            <div>
              <Typography
                className="font-bold text-3xl"
                style={{ color: theme.palette.blue.main }}
              >
                ({count}) Logs
              </Typography>
            </div>
          )}

          <div className="mt-4 mb-4">
            {loading ? (
              <CircularProgress
                sx={{ color: theme.palette.orange.main }}
                className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
              />
            ) : (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }}>
                  <TableHead>
                    <TableRow>
                      {headers.map((header) => (
                        <StyledTableCell
                          className={`${
                            header.id === "timestamp" ? "w-1/4" : "w-3/4"
                          }
                          ${
                            header.id === "description"
                              ? "text-start pl-5"
                              : "text-center"
                          }`}
                          key={header.id}
                          align="center"
                        >
                          {header.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {logs.length > 0 ? (
                      logs.map((log) => (
                        <StyledTableRow key={log.id}>
                          <StyledTableCell align="center">
                            {dayjs(log.created_at).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <div
                              dangerouslySetInnerHTML={{ __html: log.content }}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell align="center" colSpan={2}>
                          No logs found
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </div>
      </StaffLayout>
    )
  );
};

export default Logs;
