import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  jobModalOpen: false,
  mechanicSelectModalOpen: false,
  reassignVal: false,
};

const modalSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    openJobModal: (state) => {
      state.jobModalOpen = true;
    },
    closeJobModal: (state) => {
      state.jobModalOpen = false;
    },
    openMechanicSelectModal: (state) => {
      state.mechanicSelectModalOpen = true;
    },
    closeMechanicSelectModal: (state) => {
      state.mechanicSelectModalOpen = false;
    },

    setReassignTrue: (state) => {
      state.reassignVal = true;
    },
    setReassignFalse: (state) => {
      state.reassignVal = false;
    },
  },
});

export const {
  openJobModal,
  closeJobModal,
  openMechanicSelectModal,
  closeMechanicSelectModal,
  setReassignTrue,
  setReassignFalse,
} = modalSlice.actions;

export default modalSlice.reducer;
