import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import { Button } from "@mui/material";
import ConfirmDialog from "../commonComponents/ConfirmDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  assignJobToDriver,
  cancelJob,
  cancelPartsOrder,
  closeJob,
} from "../../redux-slices/jobsSlice";
import {
  closeJobModal,
  openMechanicSelectModal,
  setReassignFalse,
  setReassignTrue,
} from "../../redux-slices/modalSlice";
import { useNavigate } from "react-router";

const AdminButtons = ({ id, status, fetchData }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const [text, setText] = useState("");
  const [boldText, setBoldText] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const handleCloseDontAgree = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirm = () => {
    handleCloseDontAgree();
    switch (selectedId) {
      case 0:
        dispatch(
          assignJobToDriver({
            id,
            onSuccess: () => {
              fetchData();
              dispatch(closeJobModal());
            },
          })
        );
        break;
      case 3:
        dispatch(
          closeJob({
            id,
            onSuccess: () => {
              fetchData();
              dispatch(closeJobModal());
            },
          })
        );
        break;
      case 4:
        dispatch(
          cancelPartsOrder({
            id,
            onSuccess: () => {
              fetchData();
              dispatch(closeJobModal());
            },
          })
        );
        break;
      case 5:
        dispatch(
          cancelJob({
            id,
            onSuccess: () => {
              fetchData();
              dispatch(closeJobModal());
            },
          })
        );
        break;
      default:
        break;
    }
  };

  const adminButtons = [
    {
      id: 6,
      title: "View Logs",
      show: true,
    },
    {
      id: 0,
      title: "To Driver",
      show: status === "awaiting-mechanic" || status === "awaiting-review",
    },
    {
      id: 1,
      title: "To Mechanic",
      show: status === "awaiting-mechanic",
    },
    {
      id: 2,
      title: "Reassign",
      show: status === "awaiting-review",
    },
    {
      id: 3,
      title: "Close Order",
      show: status === "awaiting-review",
    },
    {
      id: 4,
      title: "Cancel Parts Order",
      color: "red",
      show: status === "awaiting-parts",
    },
    {
      id: 5,
      title: "Cancel Order",
      color: "red",
      show: status === "awaiting-mechanic",
    },
  ];

  const handleClickOpen = (item) => {
    switch (item.id) {
      case 0:
        setSelectedId(item.id);
        setText("");
        setBoldText(item.title);
        setOpenConfirmDialog(true);
        break;
      case 1:
        setSelectedId(item.id);
        dispatch(setReassignFalse());
        dispatch(openMechanicSelectModal());
        dispatch(closeJobModal());
        break;
      case 2:
        setSelectedId(item.id);
        dispatch(setReassignTrue());
        dispatch(openMechanicSelectModal());
        dispatch(closeJobModal());
        break;
      case 3:
        setSelectedId(item.id);
        setText("Are you sure you want to");
        setBoldText(item.title);
        setOpenConfirmDialog(true);
        break;
      case 4:
        setSelectedId(item.id);
        setText("Are you sure you want to");
        setBoldText(item.title);
        setOpenConfirmDialog(true);
        break;
      case 5:
        setSelectedId(item.id);
        setText("Are you sure you want to");
        setBoldText(item.title);
        setOpenConfirmDialog(true);
        break;
      case 6:
        dispatch(closeJobModal());
        navigate(`/${user?.roles?.[0]?.name}/logs/${id}`, {
          state: { job_id: id },
        });
        break;
      default:
        break;
    }
  };

  return (
    <>
      {adminButtons.map((item) => (
        <Button
          key={item.id}
          className={`h-10 text-white mt-7 md:mr-5 rounded-lg text-base ${
            item.show ? "block" : "hidden"
          }
          ${item.id === 4 ? "md:w-44" : "md:w-36"}`}
          style={{
            background:
              item.color === "red"
                ? theme.palette.red.main
                : theme.palette.orange.main,
            textTransform: "none",
          }}
          onClick={() => handleClickOpen(item)}
        >
          {item.title}
        </Button>
      ))}
      <ConfirmDialog
        open={openConfirmDialog}
        onClose={handleCloseDontAgree}
        onConfirm={handleConfirm}
        text={text}
        boldText={boldText}
      />
    </>
  );
};

export default AdminButtons;
