import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

export const getPdf = createAsyncThunk(
  "jobsSlice/getPdf",
  async (id, { rejectWithValue }) => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get-modal-pdf`,
        {
          params: { id },
          responseType: "blob",
        }
      );

      // Create a URL for the blob and trigger the download
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      window.open(url, "_blank");
      window.URL.revokeObjectURL(url);

      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getPickupOrdersPdf = createAsyncThunk(
  "jobsSlice/getPickupOrdersPdf",
  async (tab, { rejectWithValue }) => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get-pickup-jobs-pdf`,
        {
          params: { tab },
          responseType: "blob",
        }
      );

      // Create a URL for the blob and trigger the download
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      window.open(url, "_blank");
      window.URL.revokeObjectURL(url);

      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const pdfSlice = createSlice({
  name: "pdfSlice",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPdf.rejected, (state, action) => {
      toast.error(action.payload?.message ?? "Error fetching pdf");
    });
    builder.addCase(getPdf.fulfilled, (state, action) => {
      toast.success(action.payload?.message ?? "Pdf fetched successfully");
    });

    builder.addCase(getPickupOrdersPdf.rejected, (state, action) => {
      toast.error(action.payload?.message ?? "Error fetching pdf");
    });
    builder.addCase(getPickupOrdersPdf.fulfilled, (state, action) => {
      toast.success(action.payload?.message ?? "Pdf fetched successfully");
    });
  },
});

export const {} = pdfSlice.actions;

export default pdfSlice.reducer;
