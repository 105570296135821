import React from "react";
import { MediaWithHeading } from "./MediaWithHeading";

const ShowMedia = ({ userRole, media, fetchJobData }) => {
  return (
    <div>
      {media?.map((media, index) => {
        return (
          <div className="mb-10">
            <MediaWithHeading
              key={index}
              userRole={userRole}
              heading={media?.role_name}
              source={media?.media}
              fetchJobData={fetchJobData}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ShowMedia;
