import { Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import dayjs from "dayjs";

export const DriverOrderData = ({ tab, data }) => {
  const theme = useTheme();

  return (
    <Grid container>
      {tab === 0 && (
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Typography
            className="text-sm font-medium mb-1"
            style={{ color: theme.palette.orange.main }}
          >
            Service Order#
          </Typography>
          <Typography
            className="text-sm font-normal mb-4"
            style={{ color: theme.palette.blue.main }}
          >
            {data?.display_id || ""}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Typography
          className="text-sm font-medium mb-1"
          style={{ color: theme.palette.orange.main }}
        >
          Assigned At
        </Typography>
        <Typography
          className="text-sm font-normal mb-4"
          style={{ color: theme.palette.blue.main }}
        >
          {dayjs(data?.date_time).format("YYYY:MM:DD HH:mm") || "N/A"}
        </Typography>
      </Grid>
      {data?.assigned_by && (
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Typography
            className="text-sm font-medium mb-1"
            style={{ color: theme.palette.orange.main }}
          >
            Assigned By
          </Typography>
          <Typography
            className="text-sm font-normal mb-4"
            style={{ color: theme.palette.blue.main }}
          >
            {data?.assigned_by || ""}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Typography
          className="text-sm font-medium mb-1"
          style={{ color: theme.palette.orange.main }}
        >
          Status
        </Typography>
        <Typography
          className="text-sm font-normal mb-4"
          style={{ color: theme.palette.blue.main }}
        >
          {data?.status || ""}
        </Typography>
      </Grid>

      {data?.vehicle && (
        <>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Typography
              className="text-sm font-medium mb-1"
              style={{ color: theme.palette.orange.main }}
            >
              License Plate
            </Typography>
            <Typography
              className="text-sm font-normal mb-4"
              style={{ color: theme.palette.blue.main }}
            >
              {data?.vehicle?.license_number || ""}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Typography
              className="text-sm font-medium mb-1"
              style={{ color: theme.palette.orange.main }}
            >
              Make & Model
            </Typography>
            <Typography
              className="text-sm font-normal mb-4"
              style={{ color: theme.palette.blue.main }}
            >
              {data?.vehicle?.make_and_model || ""}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Typography
              className="text-sm font-medium mb-1"
              style={{ color: theme.palette.orange.main }}
            >
              Year
            </Typography>
            <Typography
              className="text-sm font-normal mb-4"
              style={{ color: theme.palette.blue.main }}
            >
              {data?.vehicle?.year || ""}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <div className="w-3/5 flex justify-between items-center">
              <Typography
                className="text-sm font-medium mb-1"
                style={{ color: theme.palette.orange.main }}
              >
                Vin/Chassis #
              </Typography>
            </div>
            <Typography
              className="text-sm font-normal mb-4"
              style={{ color: theme.palette.blue.main }}
            >
              {data?.vehicle?.chassis_number || ""}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};
