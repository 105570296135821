import React from "react";
import { NavButtons } from "../receptionists/NavButtons";
import { InputAdornment, MenuItem, Select } from "@mui/material";
import search from "../../assets/images/search.svg";
import CarfixInput from "./CarfixInput";
import { useTheme } from "@emotion/react";
import FilterImg from "../../assets/images/FilterImg.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export const Navbar = ({
  tab,
  setTab,
  tabData,
  searchValue,
  setSearchValue,
  filter,
  handleFilterSelect,
  filterOptions,
}) => {
  const theme = useTheme();

  return (
    <div className="flex flex-col lg:flex-row justify-between items-center p-10">
      <div className="flex flex-col lg:flex-row items-center">
        <NavButtons tabData={tabData} currTab={tab} setTab={setTab} />
      </div>

      <div className="flex flex-col lg:flex-row items-center justify-between mt-3 lg:mt-0 w-96 lg:w-2/6">
        <div style={{ width: "48%" }}>
          {tab === 0 && filterOptions && filterOptions.length > 0 && (
            <Select
              className="rounded-xl w-full"
              value={filter}
              onChange={handleFilterSelect}
              displayEmpty
              sx={{
                color: theme.palette.blue.main,
                backgroundColor: "#fafdff",
              }}
              startAdornment={
                <InputAdornment position="start">
                  <img src={FilterImg} alt="filter" className="h-8 w-10 p-1" />
                </InputAdornment>
              }
              IconComponent={KeyboardArrowDownIcon}
            >
              {filterOptions.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.title}
                </MenuItem>
              ))}
            </Select>
          )}
        </div>

        <CarfixInput
          className="mt-3 lg:mt-0"
          style={{ width: "48%" }}
          placeholder="Search"
          type="text"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={search} alt="search" className="h-8 w-10 p-1" />
              </InputAdornment>
            ),
          }}
        />
      </div>
    </div>
  );
};
