import React, { useRef } from "react";
import Typography from "@mui/material/Typography";
import uploadImg from "../../assets/images/upload.svg";

const UploadMedia = ({ media, setMedia }) => {
  const mediaRef = useRef();

  const handleMediaSelect = (e) => {
    setMedia([...media, ...e.target.files]);
    e.target.value = null;
  };

  return (
    <div>
      {media.length < 1 && (
        <div>
          <Typography
            className="md:mt-0 text-sm font-medium mb-2"
            style={{ color: "#F3A366", fontSize: 14 }}
          >
            Upload Media
          </Typography>
          <img
            className="cursor-pointer mt-3"
            src={uploadImg}
            alt="Upload"
            onClick={() => mediaRef.current?.click()}
          />
        </div>
      )}
      <input
        multiple
        ref={mediaRef}
        id="picker"
        type="file"
        className="hidden"
        accept="image/*"
        onChange={handleMediaSelect}
      />
    </div>
  );
};

export default UploadMedia;
