import React from "react";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CursorOrderImg from "../../assets/images/close.svg";
import { OrderData } from "../../components/modalComponents/OrderData";
import { HeadingwithData } from "../../components/modalComponents/HeadingwithData";
import { OrderRemarks } from "../../components/modalComponents/OrderRemarks";
import { MediaWithHeading } from "../../components/modalComponents/MediaWithHeading";
import ShowMedia from "../../components/modalComponents/ShowMedia";
import { AdminModalTable } from "./AdminModalTable";
import AdminButtons from "./AdminModalButtons";
import { useDispatch, useSelector } from "react-redux";
import PrintImg from "../../assets/images/PrintOrderImg.png";
import { getPdf } from "../../redux-slices/pdfSlice";

const AdminModalDetails = ({
  jobModalOpen,
  closeModal,
  loading,
  job,
  fetchData,
  fetchJobData,
  id,
}) => {
  const headerTitles = [
    {
      id: 0,
      title: "Name",
    },
    {
      id: 1,
      title: "Quantity",
    },
    {
      id: 2,
      title: "Status",
    },
  ];

  const theme = useTheme();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const handlePrint = () => {
    dispatch(getPdf(id));
  };

  return (
    <Dialog
      open={jobModalOpen}
      onClose={closeModal}
      maxWidth="xl"
      scroll="paper"
      PaperProps={{
        style: {
          borderRadius: 30,
          padding: 10,
        },
      }}
    >
      <DialogTitle id="scroll-dialog-title">
        <div className="flex justify-between">
          <div className="flex">
            <Typography
              className="text-3xl font-bold"
              style={{ color: theme.palette.blue.main }}
            >
              {loading ? "Service Order" : `Service Order: ${job?.display_id}`}
            </Typography>
            <div className="flex justify-center items-center ml-5">
              <img
                src={PrintImg}
                alt="PrintImg"
                className="cursor-pointer"
                onClick={handlePrint}
              />
            </div>
          </div>
          <img
            src={CursorOrderImg}
            alt="CursorOrderImg"
            className="cursor-pointer"
            onClick={closeModal}
          />
        </div>
      </DialogTitle>

      <DialogContent className="mr-7 pl-7">
        {loading ? (
          <div className="h-60 w-96">
            <CircularProgress
              sx={{ color: theme.palette.orange.main }}
              className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
            />
          </div>
        ) : (
          <div className="orderModal justify-between">
            <div className="flex flex-col w-full 2xl:w-1/2">
              <OrderData
                editable
                fetchJobData={fetchJobData}
                showUserData
                data={job}
              />

              <HeadingwithData
                heading={"Service/Repair Required"}
                data={job?.description}
              />
              {job?.remarks?.length > 0 && (
                <div>
                  <Typography
                    className="font-semibold text-xl mb-3 mt-4"
                    style={{ color: theme.palette.blue.main }}
                  >
                    Remarks
                  </Typography>
                  <OrderRemarks remarks={job?.remarks} />
                </div>
              )}
            </div>
            <div className="flex flex-col w-full 2xl:w-1/2">
              {job?.parts_by_customer?.length > 0 && (
                <>
                  <Typography
                    className="text-sm"
                    style={{ color: theme.palette.orange.main }}
                  >
                    Parts Provided By User
                  </Typography>
                  <AdminModalTable
                    headerTitles={headerTitles}
                    data={job?.parts_by_customer}
                    partBy="customer"
                  />
                </>
              )}
              {job?.parts_by_garage?.length > 0 && (
                <>
                  <Typography
                    className="text-sm"
                    style={{ color: theme.palette.orange.main }}
                  >
                    Pick up Requests
                  </Typography>
                  <AdminModalTable
                    headerTitles={headerTitles}
                    data={job?.parts_by_garage}
                    partBy="garage"
                  />
                </>
              )}
              {job?.media_by_customer?.length > 0 && (
                <div className="mb-5 mt-5">
                  <MediaWithHeading
                    heading={"Client"}
                    source={job?.media_by_customer}
                  />
                </div>
              )}
              <div className="mt-5">
                <ShowMedia
                  userRole={user?.roles?.[0]?.name}
                  media={job?.media_by_garage}
                  fetchJobData={fetchData}
                />
              </div>
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <AdminButtons id={id} status={job?.status} fetchData={fetchData} />
      </DialogActions>
    </Dialog>
  );
};

export default AdminModalDetails;
