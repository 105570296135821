import { Welcome } from "../pages/clientPages/Welcome";
import { ServiceForm } from "../pages/clientPages/ServiceForm";
import ErrorPage from "../utils/error-page";
const addClientRoute = (route) => {
  return "/" + route;
};

const clientRoutes = [
  {
    path: addClientRoute(""),
    element: <Welcome />,
    errorElement: <ErrorPage />,
    public: true,
  },
  {
    path: addClientRoute("order"),
    element: <ServiceForm />,
    errorElement: <ErrorPage />,
    public: true,
  },
];

export default clientRoutes;
