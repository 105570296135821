import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import addWhiteImg from "../../assets/images/addWhite.svg";
import deleteImg from "../../assets/images/delete.svg";
import editImg from "../../assets/images/edit.svg";
import saveImg from "../../assets/images/saveTick.svg";
import { addPart, deletePart, updatePart } from "../../redux-slices/jobsSlice";
import { useDispatch } from "react-redux";
import { formatStatus } from "../../utils/helpers";

export const ReceptionistModalTable = ({
  tab,
  headerTitles,
  data,
  status,
  user_id,
  partBy,
  job_id,
  fetchJobData,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [parts, setParts] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [newPartLocation, setNewPartLocation] = useState("");
  const [newPartQuantity, setNewPartQuantity] = useState("");
  const [newPartName, setNewPartName] = useState("");

  useEffect(() => {
    if (!data) {
      return;
    }
    const formattedData = data.map((item) => ({
      location: item.location ? item.location : "N/A",
      part: item.name,
      quantity: item.quantity,
      status: item.status,
    }));
    setParts(formattedData);
  }, [data]);

  const handleChange = (index, field, value) => {
    const newParts = [...parts];
    if (field === "quantity" && value < 0) return;
    newParts[index][field] = value;
    setParts(newParts);
  };

  const insertRow = () => {
    const hiddenRow = document.getElementById("hidden-row-" + partBy);
    const newRowBtn = document.getElementById("new-row-btn-" + partBy);
    hiddenRow.classList.remove("hidden");
    newRowBtn.classList.add("hidden");
  };

  const deleteNewRow = () => {
    const hiddenRow = document.getElementById("hidden-row-" + partBy);
    const newRowBtn = document.getElementById("new-row-btn-" + partBy);
    hiddenRow.classList.add("hidden");
    newRowBtn.classList.remove("hidden");
  };

  const addNewPart = () => {
    const hiddenRow = document.getElementById("hidden-row-" + partBy);
    const newRowBtn = document.getElementById("new-row-btn-" + partBy);
    const newPart = {
      location: newPartLocation,
      part: newPartName,
      quantity: newPartQuantity,
      status: partBy === "customer" ? "available" : "pending",
    };
    if (
      newPart.part === "" ||
      newPart.quantity === "" ||
      // eslint-disable-next-line eqeqeq
      newPart.quantity == 0
    ) {
      alert("Part Name and Quantity are required");
      return;
    }
    setParts([...parts, newPart]);
    dispatch(
      addPart({
        data: {
          location: newPart.location,
          name: newPart.part,
          quantity: newPart.quantity,
          status: partBy === "customer" ? "available" : "pending",
          job_id: job_id,
          partBy: user_id,
        },
        onSuccess: () => fetchJobData(),
      })
    );
    hiddenRow.classList.add("hidden");
    newRowBtn.classList.remove("hidden");
    setNewPartLocation("");
    setNewPartName("");
    setNewPartQuantity("");
  };

  const deleteRow = (index, id) => {
    const newParts = [...parts];
    newParts.splice(index, 1);
    setParts(newParts);
    if (data[index].id) {
      dispatch(deletePart(data[index].id));
    }
  };

  const editRow = (index) => {
    setEditIndex(index);
  };

  const saveRow = (index, id) => {
    if (
      parts[index].part === "" ||
      parts[index].quantity === "" ||
      // eslint-disable-next-line eqeqeq
      parts[index].quantity == 0
    ) {
      alert("Part Name and Quantity are required");
      return;
    } else if (
      parts[index].part === data[index].name &&
      parts[index].quantity === data[index].quantity &&
      parts[index].location === data[index].location
    ) {
      setEditIndex(null);
      return;
    } else {
      setEditIndex(null);
      dispatch(
        updatePart({
          id,
          data: {
            location: parts[index].location,
            name: parts[index].part,
            quantity: parts[index].quantity,
            status: parts[index].status,
          },
          onSuccess: () => fetchJobData(),
        })
      );
    }
  };

  return (
    <div>
      <TableContainer
        className="rounded-lg mt-2 mb-5 shadow-md"
        component={Paper}
      >
        <Table>
          <TableHead style={{ backgroundColor: theme.palette.blue.main }}>
            <TableRow>
              {headerTitles.map((header) => (
                <TableCell
                  key={header.id}
                  className={`text-white ${
                    header.id === 0 ? "w-2/6 pl-6 text-start" : ""
                  } ${header.id === 1 ? "p-0 text-center" : ""} ${
                    header.id === 2
                      ? partBy !== "customer"
                        ? "text-center"
                        : "hidden"
                      : ""
                  }
                  ${
                    header.id === 3
                      ? partBy !== "customer"
                        ? "w-2/6 text-start"
                        : "hidden"
                      : ""
                  }
                  `}
                >
                  {header.title}
                </TableCell>
              ))}
              {(tab === 0 || (tab === 1 && status === "to-be-assigned")) && (
                <TableCell></TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {parts.map((part, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                {partBy !== "customer" && (
                  <TableCell className="text-center px-2">
                    {editIndex !== index ? (
                      <Typography className="text-xs text-left pl-2">
                        {part?.location || "N/A"}
                      </Typography>
                    ) : (
                      <input
                        className="w-full font-normal text-xs bg-white pl-4"
                        value={part?.location}
                        onChange={(e) =>
                          handleChange(index, "location", e.target.value)
                        }
                        placeholder="Location"
                      />
                    )}
                  </TableCell>
                )}
                <TableCell className="text-center">
                  {editIndex !== index ? (
                    <Typography className="text-xs text-left pl-2">
                      {part.part}
                    </Typography>
                  ) : (
                    <input
                      className="w-full font-normal text-xs bg-white pl-4"
                      value={part.part}
                      onChange={(e) =>
                        handleChange(index, "part", e.target.value)
                      }
                      placeholder="Part"
                    />
                  )}
                </TableCell>
                <TableCell className="px-1">
                  <input
                    className="w-full text-center font-normal text-xs bg-white"
                    type="number"
                    value={part.quantity}
                    onChange={(e) =>
                      handleChange(index, "quantity", e.target.value)
                    }
                    placeholder="Quantity"
                    disabled={editIndex !== index}
                  />
                </TableCell>
                {partBy !== "customer" && (
                  <TableCell className="p-0">
                    <div className="flex items-center justify-center">
                      <Typography className="text-center text-xs">
                        {formatStatus(part.status)}
                      </Typography>
                    </div>
                  </TableCell>
                )}
                {(tab === 0 || (tab === 1 && status === "to-be-assigned")) && (
                  <TableCell className="w-16 pl-3 pr-4">
                    <div className="flex justify-between">
                      {editIndex === index ? (
                        <img
                          className="cursor-pointer h-3 w-3"
                          src={saveImg}
                          alt="save"
                          onClick={() => saveRow(index, data[index].id)}
                        />
                      ) : (
                        <>
                          <img
                            className="cursor-pointer h-3 w-3"
                            src={editImg}
                            alt="edit"
                            onClick={() => editRow(index)}
                          />
                          <img
                            className="cursor-pointer h-3 w-3"
                            src={deleteImg}
                            alt="Delete"
                            onClick={() => deleteRow(index, data[index].id)}
                          />
                        </>
                      )}
                    </div>
                  </TableCell>
                )}
              </TableRow>
            ))}
            <TableRow
              id={`hidden-row-${partBy}`}
              className="hidden"
              sx={{
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
              }}
            >
              {partBy !== "customer" && (
                <TableCell className="text-center px-2">
                  <input
                    className="w-full font-normal text-xs bg-white pl-4"
                    placeholder="Location"
                    value={newPartLocation}
                    onChange={(e) => setNewPartLocation(e.target.value)}
                  />
                </TableCell>
              )}
              <TableCell className="text-center px-2">
                <input
                  className="w-full font-normal text-xs bg-white pl-4"
                  placeholder="Part"
                  value={newPartName}
                  onChange={(e) => setNewPartName(e.target.value)}
                />
              </TableCell>
              <TableCell className="p-0">
                <input
                  className="w-full text-center font-normal text-xs bg-white"
                  type="number"
                  value={newPartQuantity}
                  onChange={(e) => {
                    if (e.target.value < 0) return;
                    setNewPartQuantity(e.target.value);
                  }}
                  placeholder="Qty"
                />
              </TableCell>
              {partBy !== "customer" && (
                <TableCell className="text-center px-2">
                  {/* {partBy !== "customer" ? (
                  <div className="relative">
                    <select
                      className="w-full text-center font-normal text-xs appearance-none pr-8 pl-2"
                      value={newPartStatus}
                      onChange={(e) => setNewPartStatus(e.target.value)}
                    >
                      {dropdownStatusOptions.map((option, idx) => (
                        <option key={idx} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <ArrowDropDownRounded />
                    </div>
                  </div>
                ) : ( */}
                  <div className="flex items-center justify-center">
                    <Typography className="text-center text-xs pl-2">
                      Pending
                    </Typography>
                  </div>
                  {/* )} */}
                </TableCell>
              )}
              <TableCell className="w-16 pl-3 pr-4">
                <div className="flex justify-between">
                  <img
                    className="cursor-pointer h-3 w-3"
                    src={saveImg}
                    onClick={addNewPart}
                    alt="save"
                  />
                  <img
                    className="cursor-pointer h-3 w-3"
                    src={deleteImg}
                    alt="Delete"
                    onClick={deleteNewRow}
                  />
                </div>
              </TableCell>
            </TableRow>
            {(tab === 0 || (tab === 1 && status === "to-be-assigned")) && (
              <TableRow id={`new-row-btn-${partBy}`} sx={{ height: 50 }}>
                <TableCell colSpan={5}>
                  <div className="flex justify-center">
                    <img
                      className="cursor-pointer"
                      onClick={insertRow}
                      src={addWhiteImg}
                      alt="Add"
                    />
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
