import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import editImg from "../../assets/images/edit.svg";
import saveImg from "../../assets/images/saveTick.svg";
import { updatePart } from "../../redux-slices/jobsSlice";
import { useDispatch, useSelector } from "react-redux";
import { ArrowDropDownRounded } from "@mui/icons-material";
import { dropdownStatusOptions, formatStatus } from "../../utils/helpers";
import { updateItemStatus } from "../../redux-slices/generalPickupOrderSlice";

export const DriverModalTable = ({ tab, headerTitles, data, fetchJobData }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const [parts, setParts] = useState([]);
  const [editIndex, setEditIndex] = useState(null);

  useEffect(() => {
    if (!data) {
      return;
    }
    const formattedData = data.map((item) => ({
      location: item.location,
      part: item.name,
      quantity: item.quantity,
      status: item.status,
    }));
    setParts(formattedData);
  }, [data]);

  const handleChange = (index, field, value) => {
    const newParts = [...parts];
    if (field === "quantity" && value < 0) return;
    newParts[index][field] = value;
    setParts(newParts);
  };

  const editRow = (index) => {
    setEditIndex(index);
  };

  const saveRow = (index, id) => {
    if (parts[index].status === data[index].status) {
      setEditIndex(null);
      return;
    } else {
      setEditIndex(null);
      if (tab === 0) {
        dispatch(
          updatePart({
            id,
            data: {
              location: parts[index].location,
              name: parts[index].part,
              quantity: parts[index].quantity,
              status: parts[index].status,
            },
            onSuccess: () => fetchJobData(),
          })
        );
      } else {
        dispatch(
          updateItemStatus({
            id,
            data: parts[index],
            onSuccess: () => fetchJobData(),
          })
        );
      }
    }
  };

  return (
    <div>
      <TableContainer
        className="rounded-lg mt-2 mb-5 shadow-md"
        component={Paper}
      >
        <Table>
          <TableHead style={{ backgroundColor: theme.palette.blue.main }}>
            <TableRow>
              {headerTitles.map((header) => (
                <TableCell
                  key={header.id}
                  className={`text-white ${
                    header.id === 0 ? "w-2/6 pl-6 text-start" : ""
                  }
                  ${header.id === 1 ? "p-0 text-center" : ""}
                  ${header.id === 2 ? "w-1/5 p-2 text-center" : ""}
                  ${header.id === 3 ? "w-2/6 text-start" : ""}
                  `}
                >
                  {header.title}
                </TableCell>
              ))}
              {user?.roles[0].name === "driver" && (
                <TableCell className="w-1/12"></TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {parts.map((part, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell className="text-start px-2">
                  <Typography className="w-full font-normal text-xs pl-4">
                    {part.location || "N/A"}
                  </Typography>
                </TableCell>
                <TableCell className="text-start px-2">
                  <Typography className="w-full font-normal text-xs pl-4">
                    {part.part}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography className="w-full text-center font-normal text-xs">
                    {part.quantity}
                  </Typography>
                </TableCell>
                <TableCell className="p-0">
                  {editIndex !== index ? (
                    <Typography className="w-full font-normal text-center text-xs pl-4">
                      {formatStatus(part.status)}
                    </Typography>
                  ) : (
                    <div className="relative">
                      <select
                        className="w-full text-center font-normal text-xs appearance-none pl-0.5 pr-5"
                        value={part.status}
                        onChange={(e) =>
                          handleChange(index, "status", e.target.value)
                        }
                        disabled={editIndex !== index}
                      >
                        {dropdownStatusOptions.map((option, idx) => (
                          <option key={idx} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                      {editIndex === index ? (
                        <div className="absolute inset-y-0 right-0 flex items-center pointer-events-none">
                          <ArrowDropDownRounded />
                        </div>
                      ) : null}
                    </div>
                  )}
                </TableCell>

                {user?.roles[0].name === "driver" && (
                  <TableCell className="w-16 pl-3 pr-4">
                    <div className="flex justify-center">
                      {editIndex === index ? (
                        <img
                          className="cursor-pointer h-4 w-4"
                          src={saveImg}
                          alt="save"
                          onClick={() => saveRow(index, data[index].id)}
                        />
                      ) : (
                        <>
                          <img
                            className="cursor-pointer h-4 w-4"
                            src={editImg}
                            alt="edit"
                            onClick={() => editRow(index)}
                          />
                        </>
                      )}
                    </div>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
