import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import closeImg from "../../assets/images/close.svg";
import addWhiteImg from "../../assets/images/addWhite.svg";
import deleteImg from "../../assets/images/delete.svg";
import { useTheme } from "@emotion/react";
import UploadedMediaGallery from "../modalComponents/UploadedMediaGallery";
import CarfixInput from "./CarfixInput";
import { useDispatch } from "react-redux";
import { createGeneralPickupOrder } from "../../redux-slices/generalPickupOrderSlice";

const CreatePickupOrderModal = ({ open, handleClose, fetchData }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [rows, setRows] = useState([]);
  const [media, setMedia] = useState([]);
  const [description, setDescription] = useState("");
  const mediaRef = React.useRef();

  const insertRow = () => {
    setRows([...rows, { location: "", name: "", quantity: "" }]);
  };

  const handleInputChange = (index, field, value) => {
    const newRows = [...rows];
    newRows[index][field] = value;
    setRows(newRows);
  };

  const deleteRow = (e) => {
    const index = e.target.closest("tr").id.split("-")[1];
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };

  const handleCreatePickupJob = () => {
    if (rows.length === 0) {
      alert("Please add at least one item");
      return;
    }

    if (rows.some((row) => row.name === "" || row.quantity === "")) {
      alert("Please fill Name and Quantity for Each Item");
      return;
    }
    const data = {
      items: rows,
      media: media,
      description: description,
    };

    dispatch(
      createGeneralPickupOrder({
        data,
        onSuccess: () => {
          fetchData();
          handleClose();
        },
      })
    );
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
        className="rounded-3xl w-2/3 p-8"
      >
        <div className="flex justify-between">
          <Typography className="text-3xl font-bold">
            Create Pickup Order
          </Typography>
          <img
            src={closeImg}
            alt="Close"
            className="cursor-pointer"
            onClick={handleClose}
          />
        </div>

        <div className="mt-8 p-2">
          <Grid container spacing={2}>
            <Grid className="pr-5" item xs={12} md={12} lg={6}>
              <Typography
                className="text-sm font-medium"
                style={{ color: theme.palette.orange.main }}
              >
                Order Details
              </Typography>
              <TableContainer
                className="rounded-lg mt-2 mb-5 shadow-md"
                component={Paper}
              >
                <Table>
                  <TableHead
                    style={{ backgroundColor: theme.palette.blue.main }}
                  >
                    <TableRow>
                      <TableCell className="text-white">Location</TableCell>
                      <TableCell className="text-white">Name</TableCell>
                      <TableCell className="text-white text-center w-1/12">
                        Qty
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => (
                      <TableRow
                        key={index}
                        id={`row-${index}`}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell className="text-center px-2">
                          <textarea
                            className="w-full font-normal text-xs bg-white p-2"
                            placeholder="Location"
                            value={row.location}
                            rows={2}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "location",
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                        <TableCell className="text-center px-2">
                          <textarea
                            className="w-full font-normal text-xs bg-white p-2"
                            placeholder="Item Name"
                            value={row.name}
                            rows={2}
                            onChange={(e) =>
                              handleInputChange(index, "name", e.target.value)
                            }
                          />
                        </TableCell>
                        <TableCell className="p-0">
                          <input
                            className="w-full text-center font-normal text-xs bg-white"
                            type="number"
                            value={row.quantity}
                            onChange={(e) => {
                              if (e.target.value < 0) return;
                              handleInputChange(
                                index,
                                "quantity",
                                e.target.value
                              );
                            }}
                            placeholder="Qty"
                          />
                        </TableCell>
                        <TableCell className="text-center">
                          <div className="h-5 w-5 flex justify-center items-center">
                            <img
                              className="cursor-pointer"
                              src={deleteImg}
                              alt="delete"
                              onClick={deleteRow}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}

                    <TableRow id="new-row-btn" sx={{ height: 50 }}>
                      <TableCell colSpan={5}>
                        <div className="flex justify-center">
                          <img
                            className="cursor-pointer"
                            onClick={insertRow}
                            src={addWhiteImg}
                            alt="Add"
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid className="pr-5 pl-12" item xs={12} md={12} lg={6}>
              <div className="w-11/12 relative z-0 mb-5">
                <div className="flex justify-between pr-1">
                  <Typography
                    className="md:mt-0 text-sm font-medium mb-2"
                    style={{ color: "#F3A366", fontSize: 14 }}
                  >
                    Description
                  </Typography>
                </div>
                <CarfixInput
                  className="text-sm font-normal w-full"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  multiline
                  rows={4}
                  placeholder="Description"
                />
              </div>
              <UploadedMediaGallery
                media={media}
                setMedia={setMedia}
                mediaRef={mediaRef}
                orange
              />
            </Grid>
          </Grid>
        </div>

        <div className="flex justify-end mt-8">
          <Button
            className="w-60 md:w-36 h-10 text-white mt-7 md:mr-5 rounded-lg text-base"
            style={{
              background: theme.palette.orange.main,
              textTransform: "none",
            }}
            onClick={handleCreatePickupJob}
          >
            Create
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default CreatePickupOrderModal;
