import React from "react";
import Typography from "@mui/material/Typography";
import { capitalize } from "@mui/material";
import dayjs from "dayjs";

export const OrderRemarks = ({ remarks }) => {
  return (
    <div className="flex flex-col w-11/12 text-justify">
      {remarks?.map((item, index) => (
        <div key={index} className="flex flex-col mb-3">
          <div className="flex justify-between items-center">
            <Typography
              className="text-xs mb-1 mr-2"
              style={{ color: "#F3A366" }}
            >
              {capitalize(item?.user_name)}'s Remarks
            </Typography>
            <div
              className="flex-grow text-center bg-gray-200"
              style={{ height: 1 }}
            ></div>
            <Typography className="text-xs ml-2 text-gray-400">
              {dayjs(item?.created_at).format("MMM DD, YYYY h:mm A")}
            </Typography>
          </div>

          <Typography className="text-sm">{item.content}</Typography>
        </div>
      ))}
    </div>
  );
};
